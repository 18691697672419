<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="container-new-template">
    <img
      v-if="loadingSave"
      class="carga2"
      :src="require('@/assets/img/general/carga.gif')"
    />
    <div v-show="!loadingSave" class="row">
      <div class="col-2" />
      <div class="col-2">
        <!-- <div class="import-btn import-btn-template">
          <input
            type="file"
            ref="fileInput"
            style="display: none"
            @change="uploadGpt($event)"
          />
          <button @click="openFileInput">{{ langFilter("upload") }}</button>
        </div> -->
      </div>
      <div class="col-4 new-template-input-div">
        <input
          class="new-template-input"
          maxlength="58"
          minlength="1"
          type="text"
          :value="templateName"
          @input="updateTitle($event)"
        />
      </div>
      <div class="col-4 newtemplate-container-btn-div">
        <div>
          <hover :content="langFilter('resources')" :bs="true">
            <button
              class="new-template-btn new-template-btn-enabled save-icon"
              @click="openResourcesModal"
            />
          </hover>
        </div>
        <div>
          <hover :content="langFilter('saveDraft')" :bs="true">
            <button
              v-if="notShowEdit || true"
              class="new-template-btn-enabled new-template-btn draft-icon"
              @click="saveDraft"
            />
          </hover>
        </div>
        <div>
          <hover :content="langFilter('save')" :bs="true">
            <button
              :style="`background-image: url('/img/track/${
                !loadingSave ? 'save.svg' : 'save-disabled.svg'
              }')`"
              :class="[
                { 'new-template-btn-disabled': loadingSave },
                // { 'new-template-btn-enabled': allCompleted },
                // { 'new-template-btn': allCompleted },
                { 'new-template-btn-enabled': !loadingSave },
                { 'new-template-btn': true },
              ]"
              @click="saveTemplate(false, false)"
            />
          </hover>
        </div>
      </div>
    </div>
    <div v-show="!loadingSave">
      <draggable
        v-model="themes"
        draggable=".chapter"
        handle=".dragg-me-chapter"
        animation="500"
        ghostClass="ghost"
        chosenClass="chosen"
        :options="{
          animation: 500,
          handle: '.dragg-me-chapter',
          ghostClass: 'ghost',
          chosenClass: 'chosen',
          group: 'chapter',
          itemKey: 'id',
        }"
      >
        <new-template-creation-theme
          v-for="(theme, themeIndex) in themes"
          :key="themeIndex"
          :theme="theme"
          :themeIndex="themeIndex"
          :lang="lang"
          :checkedError="checkedError"
          @openModalConfirmation="openModalConfirmation($event)"
          @updateTheme="updateTheme($event)"
          @updateApart="updateApart($event)"
          @addApart="addApart($event)"
          @addQuestion="addQuestion($event)"
          @updateQuestion="updateQuestion($event)"
          @addTheme="addTheme($event)"
          @checkError="checkError($event)"
        />
      </draggable>
      <hr class="new-template-separator" />
      <!-- <div class="newtemplate-container-div margin-plus-btn">
      <hover :content="langFilter('addTheme')">
        <button @click="addTheme" class="new-btn-purple-add" />
      </hover>
    </div> -->
      <confirmation ref="modalConfirmation" :lang="lang" />
      <new-template-edit-question
        ref="modalEditQuestion"
        :myChoices="[]"
        :lang="lang"
        @editQuestion="editQuestion($event)"
        @openEditQuestionModal="openEditQuestionModal()"
      />
      <resources-modal
        v-if="resourcesModal"
        ref="modalResources"
        :lang="lang"
        :themes="themes"
        :edit="true"
        :savedResources="savedResources"
        @updateResources="updateResources($event)"
        @closeResources="openResourcesModal"
        @deleteResources="deleteResources($event)"
        @saveResources="savedResources = $event"
        @clear-resources="clearResources($event)"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { Modal } from "bootstrap";
import translationMixin from "../../../mixins/translationMixin.js";
import { VueDraggableNext } from "vue-draggable-next";
export default {
  props: [
    "lang",
    "title",
    "image",
    "space",
    "categories",
    "draft",
    "relations",
    "assistant_id",
    "assistant_evaluator_id",
  ],
  components: {
    draggable: VueDraggableNext,
  },
  data() {
    return {
      notShowEdit: false,
      savedResources: [],
      toDeleteResources: [],
      themes: [
        {
          title: "",
          description: "",
          resources: [],
          position: 0,
          preId: this.generateRandomString(),
          aparts: [
            {
              title: "",
              resources: [],
              position: 0,
              preId: this.generateRandomString(),
              questions: [
                {
                  title: "",
                  description: "",
                  type: 1,
                  choices: [{ name: "", selected: true }],
                  position: 0,
                  prompt: "",
                  preId: this.generateRandomString(),
                },
              ],
            },
          ],
        },
      ],
      confirmationType: "",
      confirmationIndex: "",
      editQuestionIndex: "",
      editQuestionModal: false,
      resourcesModal: false,
      allCompleted: false,
      draftCompleted: false,
      templateName: this.title,
      templateAssistantId: this.assistant_id,
      templateAssistantEvaluator: this.assistant_evaluator_id,
      editing: false,
      template_id: this.$route.params.template_id,
      saving: false,
      questionsDeleted: [],
      themesDeleted: [],
      apartsDeleted: [],
      loadingDraft: false,
      loadingSave: false,
      checkedError: false,
      traducciones: [
        {
          name: "addTheme",
          es: "Añadir Tema",
          en: "Add Theme",
        },
        {
          name: "themeName",
          es: "Escribir nombre del Tema...",
          en: "Write the name of the Theme...",
        },
        {
          name: "sectionName",
          es: "Escribir nombre del Apartado...",
          en: "Write the name of the Section...",
        },
        {
          name: "questionName",
          es: "Escribir la pregunta...",
          en: "Write the question...",
        },
        {
          name: "desription",
          es: "Descripción...",
          en: "Description...",
        },
        {
          name: "resources",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "saveDraft",
          es: "Guardar borrador",
          en: "Save draft",
        },
        {
          name: "save",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "completeAllFields",
          es: "Por favor, completa todos los campos",
          en: "Please, complete all the fields",
        },
        {
          name: "upload",
          es: "Importar",
          en: "Import",
        },{
          name: "errorSavingTemplate",
          es: "Hubo un error guardando su plantilla. Por favor, vuelva a intentar más tarde",
          en: "There was an error saving the template. Please try again later.",
        },
      ],
    };
  },
  created() {
    this.notShowEdit = this.$route.params.template_id === "";
    // get saved resources
    // get current url
    let url = window.location.href;
    let id = 0;
    if (url.includes("new-template")) {
      const newUrl = url.split("/");
      id = newUrl[newUrl.length - 1];
      url = `/getResourcesByTemplate/${id}`;
    } else if (url.includes("itinerary")) {
      const newUrl = url.split("/");
      id = newUrl[newUrl.length - 1];
      url = `/getResourcesByProject/${id}`;
    } else {
      const newUrl = url.split("/");
      id = newUrl[newUrl.length - 1];
      url = `/getResourcesByTrack/${id}`;
    }
    axios.get(process.env.VUE_APP_API_URL + url).then((response) => {
      const newResources = [];
      response.data[0].forEach((el) => {
        newResources.push({
          resource_id: el.resource_id,
          name: el.resource,
          link: el.link,
          solution_id: el.solution_id,
          track_id: id,
          station_id: el.station_id,
        });
      });

      response.data[1].forEach((el) => {
        // check if resource is already in array
        if (
          !newResources.find(
            (item) =>
              item.resource_id == el.resource_id &&
              item.solution_id == el.solution_id
          )
        )
          newResources.push({
            resource_id: el.resource_id,
            name: el.resource,
            link: el.link,
            solution_id: el.solution_id,
            track_id: id,
            station_id: el.station_id,
          });
      });
      response.data[2].forEach((el) => {
        // check if resource is already in array
        if (!newResources.find((item) => item.resource_id == el.resource_id))
          newResources.push({
            resource_id: el.resource_id,
            name: el.resource,
            link: el.link,
            solution_id: el.solution_id,
            track_id: id,
            station_id: el.station_id,
          });
      });
      response.data[3].forEach((el) => {
        // check if resource is already in array
        if (!newResources.find((item) => item.resource_id == el.resource_id))
          newResources.push({
            resource_id: el.resource_id,
            name: el.resource,
            link: el.link,
            solution_id: el.solution_id,
            track_id: id,
            station_id: el.station_id,
          });
      });
      this.savedResources = newResources;
    });
  },
  methods: {
    uploadGpt(event) {
      const file = event.target.files[0];

      // Get the value of the clean_project checkbox
      //const cleanProjectCheckbox = document.querySelector("#clean_project");
      //const cleanProject = cleanProjectCheckbox.checked ? "on" : "off";
      const cleanProject = "on";

      // Create a FormData instance to hold the file and other data
      const formData = new FormData();
      formData.append("doc", file);
      formData.append("clean_project", cleanProject);
      formData.append("project_id", null);
      formData.append("space_id", this.space.id);

      // Make the axios call
      axios
        .post(`${process.env.VUE_APP_API_URL}/newProject/fromFile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$emit(
            "showConfirmationModalInfo",
            "Archivo subido correctamente"
          );
        })
        .catch((error) => {
          console.error(error);
          this.$emit(
            "showConfirmationModalInfo",
            "Ha habido un error al subir el archivo. Por favor, inténtalo de nuevo."
          );
        });
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    deleteResources(resources) {
      resources.forEach((resource) => {
        this.toDeleteResources.push(resource);
      });
    },
    checkError(err) {
      this.checkedError = err;
    },
    scrollToError() {
      setTimeout(() => {
        let element = document.querySelector(".new-templeate-error-border");
        if (element) element.scrollIntoView({ behavior: "smooth" });
        element = document.querySelector(".new-template-error-input");
        if (element) element.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
    saveTemplate(draft, exit) {
      this.checkIfAllCompleted();
      if (!this.loadingSave && !this.loadingDraft) {
        if (this.allCompleted || this.draftCompleted) {
          this.saving = true;
          this.draftCompleted = false;
          let img = this.image.split("/track_headers/")[1];
          draft = draft ? 1 : 0;
          if (img == null) img = "predefined.png";
          let url = "createTemplate";
          if (this.editing) url = "updateTemplateDraft";
          //check if the questions are blank
          this.themes.forEach((theme) => {
            theme.aparts.forEach((apart) => {
              apart.questions.forEach((question) => {
                if (question.title == "")
                  // remove all the blank questions
                  apart.questions.splice(apart.questions.indexOf(question), 1);
              });
            });
          });
          if (this.template_id == "") this.template_id = null;
          this.loadingSave = true;
          axios
            .post(`${process.env.VUE_APP_API_URL}/template/${url}`, {
              name: this.templateName,
              image: img,
              themes: this.themes,
              space_id: this.space.id,
              categories: this.categories,
              template_id: this.template_id,
              lang: this.lang,
              draft: draft,
              questionsDeleted: this.questionsDeleted,
              themesDeleted: this.themesDeleted,
              apartsDeleted: this.apartsDeleted,
              toDeleteResources: this.toDeleteResources,
              savedResources: this.savedResources,
              assistant_id: this.assistant_id,
              evaluator_id: this.assistant_evaluator_id,
            })
            .then((response) => {
              if (!exit) {
                this.$emit("setLoading", true);
                if (draft == false || draft == 0) {
                  // redirect to the template reloading the page
                  window.location.href = `/templates?saved=true`;
                } else {
                  //redirect to `/new-template/${response.data.template.id}`
                  //   this.$router.replace(
                  //     `/new-template/${response.data.template.id}`
                  //   );
                  window.location.href = `/new-template/${response.data.template.id}`;
                  //   // location.reload();
                  //   console.log(response.data);
                  //   let resources = response.data.resources;
                  //   resources.forEach((resource) => {
                  //     if (!resource.link.includes("http"))
                  //       resource.link = `${process.env.VUE_APP_API_STORAGE}${resource.link}`;
                  //   });
                  //   const relations = [
                  //     response.data.relations,
                  //     response.data.questions,
                  //     resources,
                  //   ];
                  //   this.template_id = response.data.template.id;
                  //   this.$router.replace(
                  //     `/new-template/${response.data.template.id}`
                  //   );
                  //   this.getTemplateDaft(relations);
                  //   this.$emit("openAlert", {
                  //     message: "templateSaved",
                  //     colour: 2,
                  //   });
                  //   this.draftCompleted = true;
                }
              }
            })
            .catch((error) => {
              this.checkedError = true;
              this.$emit("openAlert", {
                text: this.langFilter("errorSavingTemplate"),
                successful: false,
              });
              console.log(error);
            })
            .finally(() => {
              this.loadingSave = false;
              this.loadingDraft = false;
              // wait for the request to finish and then set the saving to false 3 seconds later
              setTimeout(() => {
                this.saving = false;
              }, 3000);
            });
        } else {
          this.checkedError = true;
          this.$emit("openAlert", {
            text: this.langFilter("completeAllFields"),
            successful: false,
          });
          this.scrollToError();
        }
      }
    },
    saveDraft() {
      if (!this.saving && !this.loadingDraft && !this.loadingSave) {
        if (this.draftCompleted) this.saveTemplate(true, false);
        else {
          this.checkedError = true;
          this.$emit("openAlert", {
            text: this.langFilter("completeAllFields"),
            successful: false,
          });
          this.scrollToError();
        }
      }
    },
    updateTitle(event) {
      this.templateName = event.target.value;
      this.$emit("updateTitle", this.templateName);
      this.checkIfAllCompleted();
    },
    checkIfAllCompleted() {
      this.allCompleted =
        this.templateName != "" &&
        this.themes.every((theme) => {
          return (
            theme.title &&
            theme.aparts.every((apart) => {
              return (
                apart.title &&
                apart.questions.every((question) => {
                  return question.title;
                })
              );
            })
          );
        });
      this.draftCompleted =
        this.templateName != "" &&
        this.themes.every((theme) => {
          return (
            theme.title &&
            theme.aparts.every((apart) => {
              return apart.title;
            })
          );
        });
    },
    updateResources(data) {
      this.themes = data;
      this.openResourcesModal();
    },
    openResourcesModal() {
      this.resourcesModal = !this.resourcesModal;
    },
    clearResources(data) {
      this.savedResources = this.savedResources.filter(
        ({ resource_id: id }) => !data.includes(id),
      );
    },
    openEditQuestionModal() {
      this.$refs.modalConfirmation.confirmationMessage =
        "No saved changes will be lost";
      this.$refs.modalConfirmation.confirmationMessage2 = "";
      this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$refs.modalConfirmation.confirmationButtonText2 = "yes, continue";
      this.editQuestionModal = true;
    },
    addTheme(index) {
      const newObject = {
        title: "",
        description: "",
        resources: [],
        position: 0,
        preId: this.generateRandomString(),
        aparts: [
          {
            title: "",
            resources: [],
            position: 0,
            preId: this.generateRandomString(),
            questions: [
              {
                title: "",
                description: "",
                type: 1,
                choices: [{ name: "", selected: true }],
                position: 0,
                preId: this.generateRandomString(),
              },
            ],
          },
        ],
      };
      this.themes.splice(index + 1, 0, newObject);
      this.allCompleted = false;
      this.draftCompleted = false;
    },
    addApart(index) {
      const newObject = {
        title: "",
        resources: [],
        position: index[1] + 1,
        preId: this.generateRandomString(),
        questions: [
          {
            title: "",
            description: "",
            type: 1,
            choices: [{ name: "", selected: true }],
            position: 0,
            preId: this.generateRandomString(),
          },
        ],
      };

      // Insert the new object at the specified index
      this.themes[index[0]].aparts.splice(index[1] + 1, 0, newObject);
      this.allCompleted = false;
      this.draftCompleted = false;
    },
    addQuestion(data) {
      const newObject = {
        title: "",
        description: "",
        type: 1,
        choices: [{ name: "", selected: true }],
        position: data[2] + 1,
        preId: this.generateRandomString(),
      };
      this.themes[data[0]].aparts[data[1]].questions.splice(
        data[2] + 1,
        0,
        newObject
      );
      this.allCompleted = false;
    },
    generateRandomString() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    updateTheme(data) {
      if (data[2] === 1) {
        this.themes[data[1]].title = data[0];
      } else {
        this.themes[data[1]].description = data[0];
      }
      this.checkIfAllCompleted();
    },
    updateApart(data) {
      this.themes[data[2]].aparts[data[1]].title = data[0];
      this.checkIfAllCompleted();
    },
    updateQuestion(data) {
      this.themes[data[4]].aparts[data[3]].questions[data[2]].title = data[0];
      this.themes[data[4]].aparts[data[3]].questions[data[2]].type = data[1];
      this.checkIfAllCompleted();
    },
    editQuestion(data) {
      const index = this.editQuestionIndex;
      data.id = this.themes[index[0]].aparts[index[1]].questions[index[2]].id;
      this.themes[index[0]].aparts[index[1]].questions[index[2]] = data;
      this.editQuestionIndex = "";
      this.checkIfAllCompleted();
    },
    openModalConfirmation(data) {
      let modalName = "modalEditQuestion";
      if (data[1] === 3) {
        modalName = "modalConfirmation";
        this.confirmationType = data[0];
        this.confirmationIndex = [data[3], data[2], data[4], data[3]];
        if (this.confirmationType === 3) {
          this.$refs.modalConfirmation.confirmationMessage =
            "Are you sure you want to delete this question?";
        } else if (this.confirmationType === 2) {
          this.$refs.modalConfirmation.confirmationMessage =
            "Are you sure you want to delete this part?";
        } else {
          this.$refs.modalConfirmation.confirmationMessage =
            "Are you sure you want to delete this theme?";
        }
        this.$refs.modalConfirmation.confirmationMessage2 = "";
        this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
        this.$refs.modalConfirmation.confirmationButtonText2 = "yes, delete";
      } else {
        const index = data[2];
        const question =
          this.themes[index[0]].aparts[index[1]].questions[index[2]];
        this.editQuestionIndex = index;
        this.$refs.modalEditQuestion.title = question.title;
        this.$refs.modalEditQuestion.description = question.description;
        this.$refs.modalEditQuestion.optionSelected = question.type;
        this.$refs.modalEditQuestion.choices = question.choices;
        this.$refs.modalEditQuestion.prompt = question.prompt;
        this.$refs.modalEditQuestion.checkCompleted();
      }
      Modal.getOrCreateInstance(document.getElementById(modalName)).toggle();
    },
    confirmTrackContinue() {
      const myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      if (this.editQuestionModal) {
        this.editQuestionModal = false;
        Modal.getOrCreateInstance(
          document.getElementById("modalEditQuestion")
        ).toggle();
        this.$refs.modalEditQuestion.reset();
      } else {
        if (this.confirmationType === 3) {
          const i = this.confirmationIndex[1][0];
          const o = this.confirmationIndex[1][1];
          const q = this.confirmationIndex[1][2];
          const id = this.themes[i].aparts[o].questions[q].id;
          if (id) this.questionsDeleted.push(id);
          this.themes[i].aparts[o].questions.splice(q, 1);
        } else if (this.confirmationType === 2) {
          const i = this.confirmationIndex[3];
          const numAparts =
            this.themes[this.confirmationIndex[2]].aparts.length;
          if (numAparts > 1) {
            const id = this.themes[this.confirmationIndex[2]].aparts[i].id;
            if (id) this.apartsDeleted.push(id);
            this.themes[this.confirmationIndex[2]].aparts.splice(i, 1);
          } else {
            this.$emit("openAlert", { text: "deletePart", successful: false });
          }
        } else {
          const themes = this.themes.length;
          if (themes > 1) {
            const i = this.confirmationIndex[0];
            const id = this.themes[i].id;
            if (id) this.themesDeleted.push(id);
            this.themes.splice(i, 1);
          } else {
            this.$emit("openAlert", { text: "deleteTheme", successful: false });
          }
        }
      }
      myModal.toggle();
    },
    confirmTrackCancel() {
      const myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      this.confirmationType = "";
      this.confirmationIndex = "";
      myModal.toggle();
    },
    getTemplateDaft(relations) {
      this.editing = true;

      // Group by station_id
      const byStation = relations[0].reduce((acc, obj) => {
        if (!acc[obj.station_id]) {
          acc[obj.station_id] = [];
        }
        acc[obj.station_id].push(obj);
        return acc;
      }, {});

      // Group questions by solution_id
      const questions = relations[1].reduce((acc, obj) => {
        if (!acc[obj.solution_id]) {
          acc[obj.solution_id] = [];
        }
        acc[obj.solution_id].push(obj);
        return acc;
      }, {});

      this.themes = [];
      let stationIndex = 0;

      for (const station_id in byStation) {
        const stationGroup = byStation[station_id];
        const station = stationGroup[0];

        const stationResources = relations[2].filter(
          (resource) =>
            resource.solution_id === null &&
            resource.station_id === station.station_id
        );

        let themePosc = station.station_position ?? stationIndex;
        this.themes.push({
          id: station.station_id,
          title: station.station_name,
          description: station.station_description,
          resources: stationResources,
          aparts: [],
          position: themePosc,
        });

        // Reorder solutions by solution_position
        stationGroup.sort((a, b) => a.solution_position - b.solution_position);

        for (const solution of stationGroup) {
          let myQuestions = [];

          if (questions[solution.solution_id]) {
            myQuestions = questions[solution.solution_id].map(
              (question, questionIndex) => {
                let choices = [];
                if (question.options) {
                  choices = question.options
                    .split("[;separator;]")
                    .map((option, i) => ({
                      name: option,
                      selected: i === 0,
                    }));
                }
                return {
                  id: question.id,
                  title: question.name,
                  description: question.description,
                  prompt: question.prompt,
                  type: question.type,
                  choices: choices,
                  order: question.order,
                  position: question.position ?? questionIndex,
                };
              }
            );

            // Reorder questions by position
            myQuestions.sort((a, b) => a.position - b.position);
          }

          const solutionResources = relations[2].filter(
            (resource) =>
              resource.station_id === station.station_id &&
              resource.solution_id === solution.solution_id
          );

          this.themes[stationIndex].aparts.push({
            id: solution.solution_id,
            title: solution.solution_name,
            resources: solutionResources,
            position: solution.solution_position ?? stationIndex,
            questions: myQuestions,
          });
        }

        // Reorder solutions within each theme by position
        this.themes[stationIndex].aparts.sort(
          (a, b) => a.position - b.position
        );
        stationIndex++;
      }

      // Reorder themes by position
      this.themes.sort((a, b) => a.position - b.position);

      this.templateName = this.title;
      this.templateAssistantId = this.template_assistant_id;
      this.templateAssistantName = this.template_assistant_name;
      this.checkIfAllCompleted();
      this.$emit("setLoading", false);
    },
  },

  watch: {
    title: function (val) {
      this.templateName = val;
    },
    relations: function (relations) {
      this.getTemplateDaft(relations);
    },
    template_assistant_id: function (val) {
      this.templateAssistantId = val;
    },
    template_assistant_name: function (val) {
      this.templateAssistantName = val;
    },
  },
  mixins: [translationMixin],
};
</script>

<template>
  <div class="container itinerary-users-container">
    <div class="row itinerary-users-container-row">
      <div class="col-1">
        <img
          class="itinerary-user-img"
          :src="getAvatar(user.avatar)"
          alt="user"
        />
      </div>
      <div class="col-4">{{ langFilter(user.name) }} {{ user.lastname }}</div>
      <div class="col-4">{{ user.email }}</div>
      <div
        :class="`col-xl-2 col-1 ${
          editing ? 'itinerary-editing-users' : 'itinerary-user-type-container'
        }`"
        style="position: relative"
      >
        <span
          :class="`itinerary-user-type ${user.id === 0 ? 'sent-purple' : ''}`"
        >
          {{ langFilter(user.id !== 0 ? user.type : "sent") }}
        </span>
        <img
          v-show="editing && user.id !== 0"
          v-outside="hidehover"
          :src="require('@/assets/img/itinerario/options.svg')"
          alt="options"
          class="option_button_myprojects"
          @click.stop="showhover($event, user.id)"
          
        />
        <div
          v-if="hoverVisible == user.id"
          :id="'hover_header' + user.id"
          class="box-project-myprojects delete-button-myprojects"
          style="display: block;"
        >
          <p
            id="text_options"
            class="box-project-myprojects-option m-1"
            @click="removeUser()"
          >
            <a class="delete-icon">
              <img
                class="box-project-myprojects-image"
                :src="require('@/assets/img/projects/delete.svg')"
              />
              {{ langFilter("delUser") }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  emits: ["addUser", "removeUser"],
  props: ["lang", "editing", "title", "type", "user", "i"],
  data() {
    return {
      traducciones: [
        {
          name: "member",
          es: "Miembro",
          en: "Member",
        },
        {
          name: "tutor",
          es: "Tutor",
          en: "Tutor",
        },
        {
          name: "sent",
          es: "Invitación enviada",
          en: "Invitation sent",
        },
        {
          name: "pending",
          es: "Pendiente",
          en: "Pending",
        },
        {
          name: "delUser",
          es: "Eliminar",
          en: "Delete",
        },
      ],
      hoverVisible: 0,
    };
  },
  methods: {
    removeUser() {
      this.$emit("removeUser", { type: this.type, index: this.i });
    },
    getAvatar(avatar) {
      if (avatar.includes("http"))
        return avatar.replace("https://www.", "https://");
      return `${process.env.VUE_APP_API_STORAGE}${avatar}`;
    },
    showhover(event, id) {
      if (this.hoverVisible == id) {
        this.hoverVisible = 0;
      } else {
        this.hoverVisible = id;
      }
      event.preventDefault();
    },
    hidehover(event) {
      if (!event.target.classList.contains("option_button_itinerary")) {
        this.hoverVisible = 0;
      }
    },
  },
};
</script>

<template>
  <div>
    <header-general
      :title="langFilter('title_my_itineraries_screen')"
      :src="require('@/assets/img/sidebar/hovers/icon-itinerary.svg')"
    />
    <div class="templates-MainContainer">
      <div class="templates-tools-container">
        <div class="viewSelector">
          <div
            class="viewSelector-templates"
            :class="{ active: page == 0 }"
            @click="page = 0"
          >
            {{ langFilter("Templates") }}
          </div>
          <svg
            width="2"
            height="33"
            viewBox="0 0 2 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="viewSelector-separator"
          >
            <path d="M1 32V1" stroke="#6C7792" stroke-linecap="round" />
          </svg>
          <div
            class="viewSelector-drafts"
            :class="{ active: page == 1 }"
            @click="page = 1"
          >
            {{ langFilter("Drafts") }}
          </div>
        </div>
        <div class="templates-tools">
          <div class="templates-NewTemplateButtonIcon" @click="onChangeView()">
            <svg
              class="templates-NewTemplateIcon"
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="7.1875"
                y1="1"
                x2="7.1875"
                y2="12"
                stroke="#301889"
                stroke-width="2"
                stroke-linecap="round"
              />
              <line
                x1="12.5"
                y1="6.6875"
                x2="1.5"
                y2="6.6875"
                stroke="#301889"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div id="searchbar-templates" class="tool-search-templates">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="tool-image-search-templates"
              @click="focusSearchBar"
            >
              <circle cx="8.5" cy="8" r="7" stroke="#301889" stroke-width="2" />
              <line
                x1="13.9142"
                y1="14"
                x2="18.5"
                y2="18.5858"
                stroke="#301889"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>

            <input
              id="template-search"
              v-model="filters.name"
              class="tool-search-templates-input"
              :placeholder="langFilter('search-template')"
              name="searchbar-templates"
              type="text"
              @focus="search_bar(true)"
              @blur="search_bar(false)"
            />
          </div>
          <div class="templates-list-categories-dropdown">
            <div class="templates-FilterCategory" @click="openPopUp">
              <div class="templates-FilterCategoryText">
                {{ langFilter("Categories") }}
              </div>
              <div class="templates-FilterCategoryIcon">
                <svg
                  width="7"
                  height="5"
                  viewBox="0 0 7 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L3.5 4L4.75 2.5L6 0.999999"
                    stroke="#301889"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              id="filter-programs-container"
              class="templates-list-categories-dropdown-content"
            >
              <ul style="padding: 0; margin: 0">
                <li id="category-filter-all">
                  <label class="categoryRowAll" @click="selectAllCategories">
                    <span
                      v-if="filters.categories.length != listCategories.length"
                      class="categoryAll"
                      >{{ langFilter("Select all") }}</span
                    >
                    <span v-else class="categoryAll">{{
                      langFilter("Delete all")
                    }}</span>
                    <span
                      id="input-checkmark"
                      class="category-checkmark"
                      :class="
                        filters.categories.length == listCategories.length
                          ? 'category-checkmark-check'
                          : 'category-checkmark'
                      "
                    >
                      <img
                        class="templates-tick_image"
                        :src="require('@/assets/img/projects/tick.svg')"
                    /></span>
                  </label>
                </li>

                <li
                  v-for="(category, categoriesIndex) in listCategories"
                  :id="'category-filter-' + categoriesIndex"
                  :key="categoriesIndex"
                >
                  <label class="categoryRow">
                    <span class="categoryName">{{ category.name }}</span>
                    <input
                      :id="'category_' + category.id"
                      v-model="filters.categories"
                      :value="category.id"
                      type="checkbox"
                      class="categoryCheckbox"
                    />
                    <span
                      id="input-checkmark"
                      :class="
                        filters.categories.includes(category.id)
                          ? 'category-checkmark-check'
                          : 'category-checkmark'
                      "
                    >
                      <img
                        class="templates-tick_image"
                        :src="require('@/assets/img/projects/tick.svg')"
                    /></span>
                  </label>
                </li>
              </ul>
            </div>
            <div
              id="backdrop-filter-by-categories"
              class="teamplates-backdrop-filter-by-categories"
              @click="clickOutsideDiv($event)"
            ></div>
          </div>
        </div>
      </div>
      <div v-if="page == 0 && templatesShown">
        <div
          v-for="(category, categoriesIndex) in categoriesContainer"
          :id="'categoryContainer-' + categoriesIndex"
          :key="categoriesIndex"
          class="templates-categoryContainer"
        >
          <div class="templates-categoryContainer-title">
            {{ category.name }}
          </div>
          <div class="templates-templatesList">
            <div
              v-for="(template, templatesIndex) in templatesFiltered"
              :id="'template-card-' + templatesIndex"
              :key="templatesIndex"
            >
              <div
                id="trackSelector"
                @click="onTemplateClick($event, template)"
              >
                <div
                  v-if="
                    template.category_id == category.id &&
                    template.draft == page
                  "
                  class="templates-templateCard"
                >
                  <img
                    v-show="cargaGif == 0"
                    :id="'options_button' + template.id"
                    v-outside="hidehover"
                    class="option_button_templates"
                    :src="require('@/assets/img/itinerario/options.svg')"
                    @click.stop="
                      showhover($event, template.id, template.category_id)
                    "
                  />
                  <img
                    v-show="cargaGif == template.id"
                    :id="'options_button' + template.id"
                    class="option_button_templates_carga"
                    :src="require('@/assets/img/general/carga.gif')"
                  />
                  <span
                    v-if="hoverVisible == template.id + template.category_id"
                    :id="'hover_header' + template.id"
                    class="hide-options-itinerary"
                    style="
                      position: relative;
                      margin-left: 23px;
                      margin-top: 21px;
                      height: 0;
                      display: block;
                    "
                  >
                    <div class="box-project-templates delete-button-templates">
                      <p
                        id="text_options"
                        class="box-project-myprojects-option"
                        @click.stop="redirectTemplateId(template.id)"
                      >
                        <img
                          class="box-project-myprojects-image"
                          :src="require('@/assets/img/projects/edit.svg')"
                        />
                        {{ langFilter("editTemplate") }}
                      </p>
                      <p
                        id="text_options"
                        class="box-project-myprojects-option"
                        @click.stop="openModalConfirmation(template.id)"
                      >
                        <a class="delete-icon">
                          <img
                            class="box-project-myprojects-image"
                            :src="require('@/assets/img/projects/delete.svg')"
                          />
                          {{ langFilter("delTemplate") }}
                        </a>
                      </p>
                      <p
                        id="text_options"
                        class="box-project-myprojects-option"
                        @click.stop="
                          copyTemplate(template.id, template.space_id)
                        "
                      >
                        <img
                          class="box-project-myprojects-image"
                          :src="require('@/assets/img/projects/copy.svg')"
                        />
                        {{ langFilter("copyTemplate") }}
                      </p>
                    </div>
                  </span>

                  <div
                    class="templates-templateCardImage"
                    :style="`background-image: url('${getTrackHeader(
                      template.img
                    )}'); `"
                  ></div>
                  <div class="templates-templateCardText">
                    <p
                      class="templates-templateCardTitle"
                      :class="{ draft: page == 1 }"
                    >
                      {{ template.name }}
                    </p>
                    <p>
                      <span
                        v-if="
                          template.created_by != '' &&
                          template.created_by != undefined &&
                          template.created_by != null
                        "
                        class="templates-templateCardCreatedBy"
                        >{{ langFilter("Created by") }}:
                        <span
                          class="templateCardPurple"
                          :class="{ draft: page == 1 }"
                          >{{ template.creatorName }}</span
                        >
                      </span>
                      <span
                        class="templates-templateCardCategory"
                        :class="{ draft: page == 1 }"
                        >{{ langFilter("Category") }}:
                        <span
                          class="templateCardPurple"
                          :class="{ draft: page == 1 }"
                          >{{ template.category_name }}</span
                        >
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="categoriesContainer.length == 0"
          id="info-no-templates"
          style="
            font-family: Poppins-Regular;
            font-size: 20;
            text-align: center;
            width: 100%;
            margin: 200px 0px 200px 0px;
          "
        >
          {{ langFilter("No-templates") }}
        </div>
      </div>
      <div v-if="page == 1 && templatesShown">
        <div
          v-for="(category, categoriesIndex) in draftCategoriesContainer"
          :id="'categoryContainer-' + categoriesIndex"
          :key="categoriesIndex"
          class="templates-categoryContainer"
        >
          <div class="templates-categoryContainer-title">
            {{ category.name }}
          </div>
          <div class="templates-templatesList">
            <div
              v-for="(template, templatesIndex) in templatesFiltered"
              :id="'template-card-' + templatesIndex"
              :key="templatesIndex"
            >
              <div
                id="trackSelector"
                @click="onTemplateClick($event, template)"
              >
                <div
                  v-if="
                    template.category_id == category.id &&
                    template.draft == page
                  "
                  class="templates-templateCard"
                >
                  <img
                    v-show="cargaGif == 0"
                    :id="'options_button' + template.id"
                    v-outside="hidehover"
                    class="option_button_templates"
                    :src="require('@/assets/img/itinerario/options.svg')"
                    @click.stop="
                      showhover($event, template.id, template.category_id)
                    "
                  />
                  <img
                    v-show="cargaGif == template.id"
                    :id="'options_button' + template.id"
                    class="option_button_templates_carga"
                    :src="require('@/assets/img/general/carga.gif')"
                  />
                  <span
                    v-if="hoverVisible == template.id + template.category_id"
                    :id="'hover_header' + template.id"
                    class="hide-options-itinerary"
                    style="
                      position: relative;
                      margin-left: 23px;
                      margin-top: 21px;
                      height: 0;
                      display: block;
                    "
                  >
                    <div class="box-project-templates delete-button-templates">
                      <p
                        id="text_options"
                        class="box-project-myprojects-option"
                        @click.stop="redirectTemplateId(template.id)"
                      >
                        <img
                          class="box-project-myprojects-image"
                          :src="require('@/assets/img/projects/edit.svg')"
                        />
                        {{ langFilter("editTemplate") }}
                      </p>
                      <p
                        id="text_options"
                        class="box-project-myprojects-option"
                        @click.stop="openModalConfirmation(template.id)"
                      >
                        <img
                          class="box-project-myprojects-image"
                          :src="require('@/assets/img/projects/delete.svg')"
                        />
                        {{ langFilter("delTemplate") }}
                      </p>
                      <p
                        id="text_options"
                        class="box-project-myprojects-option"
                        @click.stop="
                          copyTemplate(template.id, template.space_id)
                        "
                      >
                        <img
                          class="box-project-myprojects-image"
                          :src="require('@/assets/img/projects/copy.svg')"
                        />
                        {{ langFilter("copyTemplate") }}
                      </p>
                    </div>
                  </span>

                  <div
                    class="templates-templateCardImage"
                    :style="`background-image: url('${getTrackHeader(
                      template.img
                    )}'); `"
                  ></div>
                  <div class="templates-templateCardText">
                    <p
                      class="templates-templateCardTitle"
                      :class="{ draft: page == 1 }"
                    >
                      {{ template.name }}
                    </p>
                    <p>
                      <span
                        v-if="
                          template.created_by != '' &&
                          template.created_by != undefined &&
                          template.created_by != null
                        "
                        class="templates-templateCardCreatedBy"
                        >{{ langFilter("Created by") }}:
                        <span
                          class="templateCardPurple"
                          :class="{ draft: page == 1 }"
                          >{{ template.creatorName }}</span
                        >
                      </span>
                      <span
                        class="templates-templateCardCategory"
                        :class="{ draft: page == 1 }"
                        >{{ langFilter("Category") }}:
                        <span
                          class="templateCardPurple"
                          :class="{ draft: page == 1 }"
                          >{{ template.category_name }}</span
                        >
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="draftCategoriesContainer.length == 0"
          id="info-no-templates"
          style="
            font-family: Poppins-Regular;
            font-size: 20;
            text-align: center;
            width: 100%;
            margin: 200px 0px 200px 0px;
          "
        >
          {{ langFilter("No-drafts") }}
        </div>
      </div>
    </div>
    <div v-if="creatingCopy" class="templates-creatingCopy">
      {{ langFilter("creatingCopy") }}
    </div>

    <div v-if="!templatesShown">
      <img
        :src="require('@/assets/img/general/loading.gif')"
        class="loading-rocket-templates"
      />
    </div>

    <alertMessage ref="alerts" :lang="lang" />
    <confirmation ref="modalConfirmation" :lang="lang" />

    <!-- <new-track
            v-show="view === 1"
            v-if="isAdmin"
            v-on:newTrack="changeView"
            :lang="lang"
        ></new-track> -->
    <templateModal
      ref="templateModal"
      :lang="lang"
      :track="trackSelected"
      :categories="listCategories"
      :selectableProject="false"
      :_token="_token"
      @show-templates="showTemplates"
    />
  </div>
</template>
<script>
/* eslint-disable */
import translationMixin from "../../mixins/translationMixin.js";
// import newTrack from "./newTrack/NewTrackComponent.vue";
import confirmation from "../ConfirmationComponent.vue";
import TemplateModal from "./TemplateModalComponent.vue";
import axios from "axios";
import $ from "jquery";
import { Modal } from "bootstrap";

export default {
  components: {
    // newTrack,
    confirmation,
    TemplateModal,
  },

  props: ["role", "lang", "space"],

  data() {
    return {
      view: 0,
      page: 0,
      deleteTemplateId: 0,
      selectorModal: 0,
      cargaGif: 0,
      isAdmin: false,
      hoverVisible: 0,
      listCategories: [],
      trackSelected: "",
      listTemplates: [],
      templateCopyId: 0,
      creatingCopy: false,
      listUsersCustomers: [],
      templatesShown: false,
      errors: [],
      succesful: [],
      filters: {
        name: [],
        categories: [],
      },
      traducciones: [
        {
          name: "error",
          es: "Error, por favor contacta con support@acceleralia.com.",
          en: "Error, please contact support@acceleralia.com.",
        },
        {
          name: "no_tracks",
          es: "No hay tracks disponibles.",
          en: "No tracks available.",
        },
        {
          name: "title_my_itineraries_screen",
          es: "Plantillas",
          en: "Templates",
        },
        {
          name: "search-template",
          es: "Buscar plantilla...",
          en: "Search template...",
        },
        {
          name: "Categories",
          es: "Categorías",
          en: "Categories",
        },
        {
          name: "Templates",
          es: "Plantillas",
          en: "Templates",
        },
        {
          name: "Drafts",
          es: "Borradores",
          en: "Drafts",
        },
        {
          name: "Select all",
          es: "Selec. todo",
          en: "Select all",
        },
        {
          name: "Delete all",
          es: "Borrar todo",
          en: "Delete all",
        },
        {
          name: "Created by",
          es: "Creado por",
          en: "Created by",
        },
        {
          name: "Category",
          es: "Categoría",
          en: "Category",
        },
        {
          name: "No-templates",
          es: "No hay plantillas disponibles",
          en: "There are no templates available",
        },
        {
          name: "No-drafts",
          es: "No hay borradores disponibles",
          en: "There are no drafts available",
        },
        {
          name: "delTemplate",
          es: "Eliminar",
          en: "Delete",
        },
        {
          name: "copyTemplate",
          es: "Copiar",
          en: "Copy",
        },
        {
          name: "editTemplate",
          es: "Editar",
          en: "Edit",
        },
        {
          name: "creatingCopy",
          es: "Creando copia...",
          en: "Creating copy...",
        },
        {
          name: "'The new copy has been created'",
          es: "La nueva copia ha sido creada",
          en: "'The new copy has been created'",
        },
      ],
    };
  },
  mounted() {
    const saved = this.$route.query.saved;
    if (saved) {
      this.openAlert("templateSavedAlert");
    }
  },
  created() {
    // pide todos los usuarios del espacio y los almacena en listUsersCustomers
    axios
      .post(`${process.env.VUE_APP_API_URL}/myProjects/getCustomerUsers`, {
        params: {
          space: this.space.id,
        },
      })
      .then((response) => {
        this.listUsersCustomers = response.data;
      });

    // pide todos los roles de todos los usuarios y los que son tutor (1) los almacena en isAdmin
    axios
      .get(`${process.env.VUE_APP_API_URL}/general/getUserRoleBySpace`, {})
      .then((response) => {
        this.isAdmin = response.data.user_role == 1;
      })
      .then(() => {
        const pathname = window.location.pathname;
        pathname.split("/");
        if (pathname[4] === "-") {
          if (this.isAdmin) {
            this.view = 1;
          } else {
            history.replaceState(null, null, `/templates`);
          }
        }
      });

    // pide todas las categorias y las almacena en listCategories
    axios
      .post(`${process.env.VUE_APP_API_URL}/tracks/getAllTemplatesCategory`, {
        lang: this.lang,
      })
      .then((response) => {
        this.listCategories = response.data;
      });

    // pide todos los tracks y los almacena en listTemplates, después ejecuta mergeCategories y muestra las plantillas, activando showTemplates
    axios
      .post(`${process.env.VUE_APP_API_URL}/tracks/getAllTracks`, {
        lang: this.lang,
        space_id: this.space.id,
      })
      .then((response) => {
        this.listTemplates = response.data;
        this.mergeCategories();
      });
  },
  computed: {
    templatesFiltered() {
      let templatesFiltered = this.listTemplates;
      if (this.filters.name.length > 0) {
        templatesFiltered = templatesFiltered.filter((template) => {
          return template.name
            .toLowerCase()
            .includes(this.filters.name.toLowerCase());
        });
      }
      if (this.filters.categories.length > 0) {
        templatesFiltered = templatesFiltered.filter((template) => {
          return this.filters.categories.includes(template.category_id);
        });
      }
      return templatesFiltered;
    },

    categoriesContainer() {
      return this.getCategoriesContainer(0);
    },

    draftCategoriesContainer() {
      return this.getCategoriesContainer(1);
    },
  },
  methods: {
    getCategoriesContainer(draft) {
      const categoriesShown = [];
      this.listCategories.forEach((category) => {
        this.templatesFiltered.forEach((template) => {
          if (category.id === template.category_id && template.draft == draft) {
            categoriesShown.push(category);
          }
        });
      });

      return categoriesShown.filter(
        (category, index, self) =>
          index === self.findIndex((t) => t.id === category.id)
      );
    },

    mergeCategories() {
      this.listTemplates.forEach((template) => {
        const categoryNames = this.listTemplates
          .filter(
            (t) =>
              t.id === template.id && t.category_id !== template.category_id
          )
          .map((t) => t.category_name);

        if (categoryNames.length > 0) {
          categoryNames.push(template.category_name);
          template.category_name = [...new Set(categoryNames)].join(", ");
        }
      });
    },

    // devuelve la imagen de la plantilla
    getTrackHeader(track) {
      return `${process.env.VUE_APP_API_STORAGE}/img/track_headers/${track}`;
    },

    // cambia la vista entre plantillas y borradores
    changeView() {
      if (this.view == 0) {
        this.view = 1;
      } else {
        this.view = 0;
      }
    },

    // añade la clase search-big-templates al input de búsqueda, para que se haga grande
    search_bar(show) {
      if (show) {
        $(".tool-search-templates").addClass("search-big-templates");
      } else {
        $(".tool-search-templates").removeClass("search-big-templates");
      }
    },

    // abre el filtro de categorías
    openPopUp: function (el) {
      if ($(el.currentTarget).next().is(":visible")) {
        $(el.currentTarget).next().hide();
        $(el.currentTarget).next().next().hide();
        this.filtered = false;
      } else {
        $(el.currentTarget).next().next().show();
        $(el.currentTarget).next().show();
        this.filtered = true;
      }
    },

    // selecciona todas las categorías en el filtro
    selectAllCategories() {
      if (this.filters.categories.length == this.listCategories.length) {
        this.filters.categories = [];
      } else {
        this.filters.categories = [];
        for (let i = 0; i < this.listCategories.length; i++) {
          this.filters.categories.push(this.listCategories[i].id);
        }
      }
    },

    // hace focus en el input de búsqueda
    focusSearchBar() {
      $("#template-search").focus();
    },

    // redirecciona a la vista de NewTrack
    onChangeView() {
      this.$router.push({ name: "NewTemplate" });
    },

    /*
     *   muestra el menú de opciones de la plantilla
     *   necesita el id de la plantilla y el id de la categoría para que no se muestre en todas las plantillas
     */
    showhover(event, id, category_id) {
      if (this.hoverVisible == id) {
        this.hoverVisible = 0;
        $("#options_button" + id)
          .parent()
          .css("cursor", "pointer");
      } else {
        this.hoverVisible = id + category_id;
        $("#options_button" + id)
          .parent()
          .css("cursor", "default");
      }
      event.preventDefault();
    },

    // oculta el menú de opciones de la plantilla
    hidehover(event) {
      if (!event.target.classList.contains("option_button_itinerary")) {
        this.hoverVisible = 0;
        $(".templates-templateCard").removeAttr("style");
      }
      // if(this.hoverVisible==0){}
    },

    /*
     *   abre el modal de confirmación de eliminación de plantilla
     *   necesita el id de la plantilla para eliminarla
     */
    openModalConfirmation(templateId) {
      this.deleteTemplateId = templateId;
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      this.$refs.modalConfirmation.confirmationMessage =
        "Está seguro que desea eliminar la plantilla?";
      this.$refs.modalConfirmation.confirmationMessage2 = "";
      this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$refs.modalConfirmation.confirmationButtonText2 = "Texto";
      myModal.toggle();
    },

    // cierra el modal de confirmación de eliminación de plantilla
    confirmTrackCancel() {
      event.preventDefault();
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.hide();
      $(".templates-templateCard").removeAttr("style");
    },

    /*
     *   cierra el modal de confirmación de eliminación de plantilla y muestra el gif de carga durante 300ms
     *   envía la petición de eliminación de plantilla al servidor
     */
    confirmTrackContinue() {
      event.preventDefault();
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.hide();
      $(".templates-templateCard").removeAttr("style");

      this.cargaGif = this.deleteTemplateId;

      setTimeout(() => {
        this.cargaGif = 0;
        this.listTemplates = this.listTemplates.filter((template) => {
          return template.id != this.deleteTemplateId;
        });
      }, 300);

      axios.post(`${process.env.VUE_APP_API_URL}/tracks/deleteTemplate`, {
        template_id: this.deleteTemplateId,
      });
    },

    // abre el modal que contiene la información de la plantilla
    onTemplateClick(event, value) {
      this.trackSelected = value;
      console.log(value);
      this.selectorModal = 0;
      $("#select-modal-track").val(0);
      if (event.target.id != "text_options") {
        var myModal = Modal.getOrCreateInstance(
          document.getElementById("trackModal")
        );
        myModal.toggle();
      }
    },

    /*
     *   envía la petición de copia de plantilla al servidor
     *   envía la petición de obtención de la plantilla copiada al servidor
     *   muestra el mensaje que indica que la plantilla se ha copiado correctamente
     *   pide otra vez todas las preguntas, temas, recursos
     */
    copyTemplate(id, space_id) {
      this.hoverVisible = 0;
      this.creatingCopy = true;
      setTimeout(() => {
        var template = this.listTemplates.find((template) => {
          return template.id == id;
        });

        axios
          .post(`${process.env.VUE_APP_API_URL}/tracks/copyTemplate`, {
            template_id: id,
            space_id: space_id,
            category: template.category_id,
            lang: this.lang,
          })
          .then((response) => {
            this.templateCopyId = response.data;
            axios
              .post(`${process.env.VUE_APP_API_URL}/tracks/getCopiedTemplate`, {
                lang: this.lang,
                template_id: this.templateCopyId,
              })
              .then((response) => {
                this.creatingCopy = false;
                this.openAlert("succesfulTemplateCopy");
                response.data.forEach((template) => {
                  this.listTemplates.unshift(template);
                });
                axios
                  .post(
                    `${process.env.VUE_APP_API_URL}/tracks/getTrackStatSol`,
                    {}
                  )
                  .then((response) => {
                    this.$refs.templateModal.trackStatSol = response.data;
                  });
                axios
                  .post(
                    `${process.env.VUE_APP_API_URL}/tracks/getTrackQuestions`,
                    {}
                  )
                  .then((response) => {
                    this.$refs.templateModal.questions = response.data;
                  });
                axios
                  .post(
                    `${process.env.VUE_APP_API_URL}/tracks/getTrackResourcesBySolutions`,
                    {}
                  )
                  .then((response) => {
                    this.$refs.templateModal.resources = response.data;
                  });
                axios
                  .post(
                    `${process.env.VUE_APP_API_URL}/tracks/getTrackResourcesByStations`,
                    {}
                  )
                  .then((response) => {
                    this.$refs.templateModal.resourcesStat = response.data;
                  });
              });
          });
      }, 500);
    },

    // muestra la caja que se muestra cuando una plantilla se ha copiado
    openAlert(text) {
      this.$refs.alerts.succesful = true;
      this.$refs.alerts.title = "";
      this.$refs.alerts.text = text;

      if (this.errors.length == 0) {
        this.errors.push("errors");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.$refs.alerts.purple = false;
          this.errors = [];
        }, 12000);
      }
    },

    // cierra la caja que se muestra cuando una plantilla se ha copiado
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);
      this.errors = [];
    },

    /*
     *   cada plantilla puede tener varias categorías, y en cada registro de la plantilla se guarda el id de una categoría
     *   esto junta las categorías de una plantilla en un solo campo, para que lo muestre en la vista
     */

    clickOutsideDiv(el) {
      $(el.currentTarget).hide();
      $(el.currentTarget).prev().hide();
    },

    // te dirige a la edición de la plantilla
    redirectTemplateId(id) {
      window.location.href = "/new-template/" + id;
    },

    //mostrar las templates una vez cargan todos los datos
    showTemplates() {
      this.templatesShown = true;
    },
  },
  mixins: [translationMixin],
};
</script>

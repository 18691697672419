<template>
  <div>
    <div id="header" class="no-selection-itinerary" style="padding-left: 3.5%">
      <td nowrap>
        <img
          class="logo-header"
          :src="require('@/assets/img/sidebar/hovers/icon-itinerary.svg')"
        />
        <h1
          style="padding-bottom: 4px"
          class="css-titulo-header margin-title-header"
        >
          {{ langFilter("projects") }}
        </h1>
      </td>
      <div style="margin-bottom: 70px"></div>
    </div>
    <div class="myProjects-MainContainer">
      <div class="newProjectV2-TabSelectorContainer">
        <div style="display: inline-block; margin: auto; text-align: center">
          <label
            v-for="({ id, name }, index) in labels"
            :key="id"
            :class="[{ success: steps > id, step: steps === id }]"
            @click="stepsFunction(index)"
          >
            {{ langFilter(name) }}
          </label>
        </div>
      </div>

      <div v-if="steps === 1">
        <div class="newProject-ProjectDataContainer">
          <label class="newProject-ProjectDataCategoryTitle">
            <span class="newProject-ProjectDataCategoryTitleNumber">
              2.
            </span>
            {{ langFilter("projectName") }}
          </label>
          <textarea
            id="newProjectV2-ProjectName"
            v-model="projectName"
            class="newProject-projectName-textarea"
            cols="1"
            rows="1"
            :placeholder="langFilter('insertProjectName') + '...'"
            @keypress.enter.prevent
          />
          <label class="newProject-ProjectDataCategoryTitle">
            <span class="newProject-ProjectDataCategoryTitleNumber">
              3.
            </span>
            {{ langFilter("selectProgram") }}
          </label>
          <div
            v-outside="disableSelect"
            class="new-projectV2-select-search-container"
            @click.stop="activeSelect"
          >
            <input
              id="newProject-ProgramInput"
              v-model="selected_program"
              class="newProject-Input placeholder-black"
              placeholder="Selecciona el programa asociado..."
              style="margin-bottom: 79px"
              readonly
            />

            <div v-if="selectMostrar" class="new-project-select-search">
              <input
                v-model="searchPrograms"
                type="text"
                :placeholder="langFilter('Search...')"
                class="newProject-Input"
              />
              <ul>
                <li
                  v-for="({ name, program_id: id }, index) in search_programs"
                  :key="index"
                  @click="selectedValueProgram(name, id)"
                >
                  {{ name }}
                </li>
              </ul>
            </div>
          </div>

          <label class="newProject-ProjectDataCategoryTitle">
            <span class="newProject-ProjectDataCategoryTitleNumber">
              4.
            </span>
            {{ langFilter("projectDuration") }}
          </label>
          <div class="row align-center">
            <div class="col-6 align-left">
              <p class="label-new-projV2">
                {{ langFilter("inicioProyecto") }}
              </p>
              <input
                id="start_date"
                v-model="start_date"
                class="input-new-projV2 date-prog"
                type="date"
              />
            </div>
            <div class="col-6 align-left">
              <p class="label-new-projV2">
                {{ langFilter("finalProyecto") }}
              </p>
              <input
                id="end_date"
                v-model="end_date"
                class="input-new-projV2 date-prog"
                type="date"
              />
            </div>
          </div>
          <label class="newProject-ProjectDataCategoryTitle">
            <span class="newProject-ProjectDataCategoryTitleNumber">
              5.
            </span>
            {{ langFilter("URL Gestor de tareas") }}
          </label>
          <textarea
            id="newProjectV2-taskManager"
            v-model="taskManager"
            :class="textareaClass"
            cols="1"
            rows="1"
            :placeholder="langFilter('URL Gestor de tareas') + '...'"
            @keypress.enter.prevent
          />
          <p v-if="taskManagerError" class="error-message">
            {{ langFilter("validUrl") }}
          </p>
        </div>
        <div
          class="newProjectV2-NextButton"
          :class="{
            'nextButton-clickable': isClickableButton,
          }"
          @click="stepsFunction(steps + 1)"
        >
          <p class="newProject-ButtonText">
            {{ langFilter("next") }}
          </p>
        </div>
      </div>

      <alertMessage ref="alerts" :lang="lang"></alertMessage>
    </div>

    <div v-if="steps === 0">
      <div class="newProject-ProjectDataContainer">
        <label
          v-if="loadingTemplates"
          class="newProject-ProjectDataCategoryTitle"
        >
          <span class="newProject-ProjectDataCategoryTitleNumber">
            1.
          </span>
          {{ langFilter("selectTemplateTitle") }}
        </label>
      </div>

      <div
        v-if="templatesShown && !loadingTemplates"
        class="newProject-ProjectBack pointer"
      >
        <img
          class="pointer"
          :src="require('@/assets/img/general/arrow-right-purple.svg')"
          alt="back"
          @click="loadingTemplates = true"
        />
        <label class="newProject-ProjectDataCategoryTitleArrow">
        {{ langFilter("arrowBack") }}
        </label>
      </div>

      <!-- Nuevas cards de opciones para plantillas -->
      <div
        v-if="loadingTemplates"
        class="newProject-templates-categoryContainer border-0"
      >
        <div class="templates-templatesList">
          <div class="templates-templateCard-selection">
            <div style="background-color: #f3f4ff">
              <img
                class="templates-templateCardImage"
                :src="require('@/assets/img/projects/chooseTemplate.svg')"
              />
            </div>
            <div class="templates-templateCardText d-flex flex-column h-100">
              <p
                class="templates-templateCardTitle flex-grow-1"
                :class="{ draft: page == 1 }"
              >
                {{ langFilter("firstOptionTitle") }}
              </p>
              <span class="templates-templateCardContent flex-grow-1">
                {{ langFilter("firstOptionText") }}</span
              >
              <div
                class="d-flex justify-content-center align-items-center flex-grow-1"
              >
                <button
                  class="btn btn-sm rounded-pill"
                  style="background-color: #815bf0; color: #ffffff"
                  @click="setNext"
                >
                  {{ langFilter("next") }}
                </button>
              </div>
            </div>
          </div>

          <div class="templates-templateCard-selection">
            <div style="background-color: #f3f4ff">
              <img
                class="templates-templateCardImage"
                :src="require('@/assets/img/projects/importTemplate.svg')"
              />
            </div>
            <div class="templates-templateCardText d-flex flex-column h-100">
              <p
                class="templates-templateCardTitle flex-grow-1"
                :class="{ draft: page == 1 }"
              >
                {{ langFilter("secondOptionTitle") }}
              </p>
              <span class="templates-templateCardContent flex-grow-1">
                {{ langFilter("secondOptionText") }}</span
              >

              <div
                class="d-flex justify-content-center align-items-center flex-grow-1"
              >
                <button
                  class="btn btn-sm rounded-pill"
                  style="background-color: #815bf0; color: #ffffff"
                  @click="toggleImportTemplateModal"
                >
                  {{ langFilter("next") }}
                </button>
              </div>

              <!-- Import Template Modal -->
              <import-template-modal
                v-if="showImportTemplate"
                :lang="lang"
                @toggle-modal="toggleImportTemplateModal"
                @file-selected="handleFileSelected"
              />
            </div>
          </div>

          <div class="templates-templateCard-selection">
            <div style="background-color: #f3f4ff">
              <img
                class="templates-templateCardImage"
                :src="require('@/assets/img/projects/createTemplate.svg')"
              />
            </div>
            <div class="templates-templateCardText d-flex flex-column h-100">
              <p
                class="templates-templateCardTitle flex-grow-1 mb-3"
                :class="{ draft: page == 1 }"
              >
                {{ langFilter("thirdOptionTitle") }}
              </p>
              <span class="templates-templateCardContent flex-grow-1 mb-3">
                {{ langFilter("thirdOptionText") }}
              </span>
              <div
                class="d-flex justify-content-center align-items-center flex-grow-1"
              >
                <button
                  class="btn btn-sm rounded-pill"
                  style="background-color: #815bf0; color: #ffffff"
                  @click="redirectNewTemplate()"
                >
                  {{ langFilter("next") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="templatesShown && !loadingTemplates">
        <div
          v-for="(category, categoriesIndex) in categoriesContainer"
          :id="'categoryContainer-' + categoriesIndex"
          :key="categoriesIndex"
          class="newProject-templates-categoryContainer"
        >
          <div class="templates-categoryContainer-title">
            {{ category.name }}
          </div>
          <div class="templates-templatesList">
            <div
              v-for="(template, templatesIndex) in listTemplates"
              :id="'template-card-' + templatesIndex"
              :key="templatesIndex"
            >
              <div
                id="trackSelector"
                @click="onTemplateClick($event, template)"
              >
                <div
                  v-if="
                    template.category_id == category.id && template.draft == 0
                  "
                  class="templates-templateCard"
                >
                  <div
                    class="templates-templateCardImage"
                    :style="`background-image: url('${getTrackHeader(
                      template.img
                    )}'); `"
                  ></div>
                  <div class="templates-templateCardText">
                    <p
                      class="templates-templateCardTitle"
                      :class="{ draft: page == 1 }"
                    >
                      {{ template.name }}
                    </p>
                    <p>
                      <span
                        v-if="
                          template.created_by != '' &&
                          template.created_by != undefined &&
                          template.created_by != null
                        "
                        class="templates-templateCardCreatedBy"
                        >{{ langFilter("Created by") }}:
                        <span
                          class="templateCardPurple"
                          :class="{ draft: page == 1 }"
                          >{{ template.creatorName }}</span
                        >
                      </span>
                      <span
                        class="templates-templateCardCategory"
                        :class="{ draft: page == 1 }"
                        >{{ langFilter("Category") }}:
                        <span
                          class="templateCardPurple"
                          :class="{ draft: page == 1 }"
                          >{{ template.category_name }}</span
                        >
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <templateModal
        ref="templateModal"
        :lang="lang"
        :track="trackSelected"
        :categories="listCategories"
        :_token="_token"
        :selectableProject="true"
      />

      <div v-if="!templatesShown && !loadingTemplates">
        <img
          :src="require('@/assets/img/general/loading.gif')"
          class="loading-rocket-templates"
        />
      </div>
    </div>

    <div v-if="steps === 2" class="container">
      <NewProjectsAssistant
        :templateExpert="assistant_id"
        :templateEvaluator="evaluator_id"
        :assistantTemplate="assistantTemplate"
        :lang="lang"
        :current-step="6"
        @update-expert="updateAssistantId"
        @update-evaluator="updateEvaluatorId"
      />
      <div
        class="newProjectV2-NextButton nextButton-clickable"
        @click="stepsFunction(steps + 1)"
      >
        <p class="newProject-ButtonText">
          {{ langFilter("next") }}
        </p>
      </div>
    </div>
  </div>

  <div v-show="steps == 3">
    <itinerary-users
      :lang="lang"
      :editing="true"
      :space="space"
      :users-in-project="usersInvite"
      :project-id="null"
      @handle-users="handleUsers($event)"
    />
    <div
      class="newProjectV2-NextButton"
      :class="{ 'nextButton-clickable': isClickableButton }"
      @click="stepsFunction(steps + 1)"
    >
      <p class="newProject-ButtonText">
        {{ langFilter("next") }}
      </p>
    </div>
  </div>

  <div v-if="steps === 4">
    <div class="newProjectV2-UsersContainer">
      <img
        :src="require('@/assets/img/projects/Tierra_Luna.png')"
        class="newProject-StartImage"
      />
      <h2 class="newProject-StartTitle">
        {{ langFilter("departing!") }}
      </h2>
      <p class="newProject-StartText">
        {{ langFilter("email sent") }}
      </p>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import "../../../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
import translationMixin from "../../mixins/translationMixin.js";
import { Modal } from "bootstrap";
import axios from "axios";
import $ from "jquery";
import translations from "./translate/NewProjectsV2Component.json";

import NewProjectsAssistantComponent from "./NewProjectsAssistantComponent.vue";

export default {
  components: {
    NewProjectsAssistant: NewProjectsAssistantComponent,
  },
  mixins: [translationMixin],
  props: [
    "userId",
    "lang",
    "role",
    "space",
    "homeNotifications",
    "spaces",
    "page",
    "_token",
  ],
  emits: ["openAlert"],
  data() {
    return {
      assistantTemplate: false,
      assistant_id: "",
      evaluator_id: "",
      showImportTemplate: false,
      taskManager: "",
      tabs: 1,
      steps: 0,
      projectName: "",
      programs: [],
      assistants: [],
      listTemplates: [],
      listCategories: [],
      trackSelected: "",
      selectorModal: 0,
      syllabus: "",
      selectMostrar: false,
      selected_program: "",
      selected_program_id: "",
      searchPrograms: "",
      selected_assistant: "",
      selected_assistant_id: "",
      selected_template: "",
      selected_file: null,
      start_date: "",
      end_date: "",
      chapters: [],
      defaultChapters: [],
      taskManagerError: false,
      errors: [],
      usersInvite: {
        tutors: [],
        members: [],
      },
      succesful: [],
      loadingTemplates: true,
      templatesShown: false,
      body: "noSave",
      info: false,
      warning: false,
      labels: [
        { id: 0, name: "template" },
        { id: 1, name: "project-info" },
        { id: 2, name: "assistants" },
        { id: 3, name: "users" },
        { id: 4, name: "start" },
      ],
      traducciones: translations,
    };
  },
  computed: {
    search_programs() {
      return this.programs.filter((program) => {
        return program.name
          .toLowerCase()
          .includes(this.searchPrograms.toLowerCase());
      });
    },
    textareaClass() {
      return [
        "newProject-projectName-textarea",
        this.taskManagerError ? "newProject-InputValidation" : "",
      ];
    },
    categoriesContainer() {
      let categories = this.listCategories;
      let categoriesShown = [];
      categories.forEach((category) => {
        this.listTemplates.forEach((template) => {
          if (category.id === template.category_id && template.draft == 0) {
            categoriesShown.push(category);
          }
        });
      });

      categoriesShown = categoriesShown.filter(
        (category, index, self) =>
          index === self.findIndex((t) => t.id === category.id)
      );

      return categoriesShown;
    },
    isCompleteBasicData() {
      return [
        this.projectName.trim(),
        this.selected_program,
        this.start_date,
        this.end_date,
      ].every(Boolean);
    },
    isClickableButton() {
      const validUsers = this.steps === 3 ? this.checkUsers() : true;
      return this.isCompleteBasicData && validUsers;
    },
    datesValid() {
      return (
        this.start_date && this.end_date && this.start_date <= this.end_date
      );
    },
  },
  watch: {
    selected_program() {
      this.selected_program_id = this.programs.find(
        (program) => program.name == this.selected_program
      ).program_id;
    },
    select_assistant() {
      this.selected_assistant_id = this.assistants.find(
        (assistant) => assistant.id == this.select_assistant
      );
    },
  },
  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/getProgramsBySpace`, {
        customer: this.space.id,
      })
      .then((response) => {
        this.programs = response.data;
      })
      .catch((error) => {
        console.error("Error getting programs by space", error);
      });
  },
  methods: {
    handleUsers({ data }) {
      this.usersInvite = data;
    },
    toggleImportTemplateModal() {
      this.showImportTemplate = !this.showImportTemplate;
    },
    updateAssistantId(newExpertId) {
      this.assistant_id = newExpertId;
    },
    updateEvaluatorId(newEvaluatorId) {
      this.evaluator_id = newEvaluatorId;
    },
    redirectNewTemplate() {
      this.selected_file = null;
      this.assistant_id = "";
      this.evaluator_id = "";
      this.assistantTemplate = false;
      this.selected_template = { id: -1 };
      this.stepsFunction(1);
    },
    handleFileSelected(file) {
      this.selected_file = file;
      this.toggleImportTemplateModal();
      this.selected_template = { id: -1 };
      this.stepsFunction(1);
    },
    setNext() {
      this.loadingTemplates = false;
      this.templatesShown = false;
      axios
        .post(`${process.env.VUE_APP_API_URL}/tracks/getAllTemplatesCategory`, {
          lang: this.lang,
        })
        .then((response) => {
          this.listCategories = response.data;
        });
      axios
        .post(`${process.env.VUE_APP_API_URL}/tracks/getAllTracks`, {
          space_id: this.space.id,
          lang: this.lang,
        })
        .then((response) => {
          this.listTemplates = response.data;
          this.mergeCategories();
          this.templatesShown = true;
        });
    },
    checkUsers() {
      return (
        this.usersInvite.tutors.length > 0 ||
        this.usersInvite.members.length > 0
      );
    },
    disableSelect(el) {
      if (
        el.target.className == "new-project-select-search-container"
          ? (this.selectMostrar = true)
          : (this.selectMostrar = false)
      );
    },
    activeSelect(el) {
      if (
        el.target.tagName == "LI"
          ? (this.selectMostrar = false)
          : (this.selectMostrar = true)
      );
    },
    selectedValueProgram(name, id) {
      this.program = id;
      this.selected_program = name;
      this.selectMostrar = false;
    },
    async stepsFunction(step) {
      if (step === 4 && !this.checkUsers()) return;

      this.stepToJump = step;
      this.templatesShown = false;
      this.loadingTemplates = true;

      if (!this.canProceedToStep(step)) return;

      this.steps = step;

      if (step === 4) {
        await this.submitNewProject();
      }
    },
    canProceedToStep(step) {
      if (step === 0) return true;

      if (!this.selected_template) return false;

      if (step === 1) {
        this.handleProjectContent();
        this.trackSelected = "";
        this.templatesShown = true;
        return true;
      }

      const isValidLastStep = step === 4 ? this.checkUsers() : true;
      return this.validationStep1() && isValidLastStep;
    },
    isValidUrl(string) {
      try {
        const url = new URL(string);
        const hostnameValidation = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
        if (hostnameValidation.test(url.hostname)) {
          return true;
        }
        return false;
      } catch (error) {
        console.error("Error with URL", error);
        return false;
      }
    },
    validateField(selector, condition) {
      const selectedClass = "newProject-InputValidation";

      !condition
        ? $(selector).addClass(selectedClass)
        : $(selector).removeClass(selectedClass);
    },
    validationStep1() {
      if (!this.isCompleteBasicData) {
        this.handleErrors({
          title: "¡Incompleted fields!",
          text: "To continue you must fill all the fields.",
        });

        this.validateFieldsInStep1();
        return false;
      }

      if (!this.datesValid) {
        this.handleErrors({
          title: "¡Incorrect dates!",
          text: "The end date must be greater than the start date.",
        });

        this.validateFieldsInStep1();
        return false;
      }

      if (this.taskManager && !this.isValidUrl(this.taskManager)) {
        this.taskManagerError = true;
      }

      return true;
    },
    validateFieldsInStep1() {
      if (!this.steps === 1) return;

      this.validateField("#newProjectV2-ProjectName", this.projectName.trim());
      this.validateField("#newProject-ProgramInput", this.selected_program);
      this.validateField("#start_date", this.datesValid);
      this.validateField("#end_date", this.datesValid);
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);
      this.errors = [];
    },
    mergeCategories() {
      let categoryNames = [];
      this.listTemplates.forEach((template) => {
        this.listTemplates.forEach((template2) => {
          if (
            template.id == template2.id &&
            template.category_id != template2.category_id
          ) {
            categoryNames.push(template2.category_name);
          }
        });
        if (categoryNames.length > 0) {
          categoryNames.push(template.category_name);
          categoryNames = categoryNames.flat();
          categoryNames = categoryNames.filter((v, i, a) => a.indexOf(v) === i);
          template.category_name = categoryNames;
        }
        //empty the array
        categoryNames = [];
      });
      this.listTemplates.forEach((template) => {
        if (Array.isArray(template.category_name)) {
          template.category_name = template.category_name.join(", ");
        }
      });
    },
    getTrackHeader(track) {
      return `${process.env.VUE_APP_API_STORAGE}/img/track_headers/${track}`;
    },
    onTemplateClick(event, value) {
      this.selected_file = null;
      this.trackSelected = value;
      this.selectorModal = 0;
      $("#select-modal-track").val(0);
      if (event.target.id != "text_options") {
        var myModal = Modal.getOrCreateInstance(
          document.getElementById("trackModal")
        );
        myModal.toggle();
      }
    },
    uploadGpt() {
      const formData = new FormData();
      formData.append("doc", this.selected_file);
      formData.append("clean_project", "on");
      formData.append("program_id", this.selected_program_id);
      formData.append("space_id", this.space.id);
      formData.append("project_name", this.projectName);
      formData.append("start_date", this.start_date);
      formData.append("end_date", this.end_date);
      formData.append("lang", this.lang);
      formData.append("taskManager", this.taskManager);

      if (this.assistant_id) formData.append("assistant_id", this.assistant_id);

      if (this.evaluator_id) formData.append("evaluator_id", this.evaluator_id);

      axios
        .post(`${process.env.VUE_APP_API_URL}/newProject/fromFile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$router.push(`/itinerary/${response.data.project.id}`);
        })
        .catch((error) => {
          console.error(error);

          alert(
            "Ha habido un error al subir el archivo. Por favor, inténtalo de nuevo más tarde."
          );
        });
    },
    async submitNewProject() {
      if (this.selected_file) {
        this.uploadGpt();
        return;
      }

      var formData = new FormData();

      formData.append("program", this.selected_program_id);
      formData.append("start_date", this.start_date);
      formData.append("end_date", this.end_date);
      formData.append("space", this.space.id);

      if (this.assistant_id) formData.append("assistant_id", this.assistant_id);

      if (this.evaluator_id) formData.append("evaluator_id", this.evaluator_id);

      if (this.selected_template.id !== -1)
        formData.append("template", this.selected_template.id);

      if (this.selected_assistant)
        formData.append("templateAssistant", this.selected_assistant_id);

      formData.append("project", this.projectName);
      formData.append("info", this.syllabus);
      formData.append("lang", this.lang);
      formData.append("taskManager", this.taskManager);
      formData.append("tutors", JSON.stringify(this.usersInvite.tutors));
      formData.append("members", JSON.stringify(this.usersInvite.members));
      formData.append("chapters", JSON.stringify(this.chapters));
      formData.append("defaultChapters", JSON.stringify(this.defaultChapters));

      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/myProjects/InsertProjectsV2`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((response) => {
          if (response.data.error) {
            this.handleErrors();
            this.steps = 0;

            return;
          }
          setTimeout(() => {
            window.location.href = "../itinerary/" + response.data.id;
          }, 1000);
        })
        .catch((error) => {
          console.error("Error creating project", error);
        });
    },
    async handleProjectContent() {
      const selectedTemplateId = this.selected_template?.id;
      const emptyProjectId = -1;

      if (selectedTemplateId === emptyProjectId) {
        this.chapters = [];
        return;
      }

      const chaptersData = await this.getTrack(selectedTemplateId);
      const { chapters = [], defaultChapters = [] } = chaptersData || {};

      this.chapters = chapters;

      this.chapters.forEach((chapter) => {
        chapter.parts.forEach((part) => {
          let i = 0;
          part.tasks.forEach((task) => {
            task.edited = false;
            i = i + 1;
          });
        });
      });

      this.defaultChapters = defaultChapters;
    },
    async getTrack(id) {
      try {
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_URL}/getTrack`,
          {
            track: id,
          }
        );

        if (data.error) {
          this.handleErrors();
          console.error(data.error);
          return;
        }

        const { chapters, default_chapters: defaultChapters } = data;
        return { chapters, defaultChapters };
      } catch (error) {
        this.handleErrors();
        console.error(error);
      }
    },
    handleErrors({ title, text }) {
      const defaultTitle = "Ups... Something went wrong!";
      const defaultText = "Please try again later";

      this.$refs.alerts.title = title || defaultTitle;
      this.$refs.alerts.text = text || defaultText;

      this.openErrorsAlert();
    },
  },
};
</script>
<style scoped>
.error-message {
  color: #eabec3;
  bottom: 10px;
  position: relative;
}
</style>

<template>
  <div v-if="loading" class="loading-data-container">
    <img :src="require('@/assets/img/general/carga.gif')" />
  </div>
  <div class="mx-5 px-5 my-4">
    <div class="d-flex">
      <router-link to="/assistants">
        <img :src="require('@/assets/img/general/new_back.svg')" alt="Back" />
      </router-link>
      <header-general
        :title="langFilter('create_new_assistant')"
        src="/img/icons/icon-robot-wink.svg"
        class="ms-3"
      />
    </div>
    <form class="d-flex flex-column mx-5" @submit.prevent="submitForm">
      <div class="row mb-5">
        <div class="col-md-5">
          <label for="assistantType" class="form-label">
            <span
              :class="[
                'd-inline-block mx-2 rounded-circle text-white text-center fw-bold bg-purpleStrong',
                {
                  'opacity-50': !form.type,
                },
              ]"
              style="padding: 0px 10px"
              >1</span
            >
            {{ langFilter("assistant_type") }} *
          </label>
          <select
            id="assistantType"
            v-model="form.type"
            class="form-select"
            required
          >
            <option value="" disabled>
              {{ langFilter("select_assistant_type") }}
            </option>
            <option value="1">{{ langFilter("expert") }}</option>
            <option value="2">{{ langFilter("evaluator") }}</option>
          </select>
        </div>

        <div class="col-md-7">
          <label for="name" class="form-label">
            <span
              :class="[
                'd-inline-block px-2 mx-2 rounded-circle text-white text-center fw-bold bg-purpleStrong',
                {
                  'opacity-50': !form.name,
                },
              ]"
            >
              2
            </span>
            {{ langFilter("assistant_name") }} *
          </label>
          <input
            id="name"
            v-model="form.name"
            type="text"
            :placeholder="langFilter('insert_assistant_name')"
            class="form-control"
            required
          />
        </div>
      </div>

      <div class="mb-5">
        <label for="description" class="form-label">
          <span
            :class="[
              'd-inline-block px-2 mx-2 rounded-circle text-white text-center fw-bold bg-purpleStrong',
              {
                'opacity-50': !form.description,
              },
            ]"
            >3</span
          >
          {{ langFilter("description") }} *
        </label>
        <textarea
          id="description"
          v-model="form.description"
          class="form-control"
          :placeholder="langFilter('insert_description')"
          rows="5"
          required
        ></textarea>
      </div>

      <div class="mb-5">
        <label for="instructions" class="form-label">
          <span
            :class="[
              'd-inline-block px-2 mx-2 rounded-circle text-white text-center fw-bold bg-purpleStrong',
              {
                'opacity-50': !form.instructions,
              },
            ]"
            >4</span
          >
          {{ langFilter("define_instruction") }} *
        </label>
        <textarea
          id="instructions"
          v-model="form.instructions"
          class="form-control"
          :placeholder="langFilter('insert_instruction')"
          rows="5"
          required
        ></textarea>
      </div>

      <div class="mb-4">
        <span
          class="d-inline-block px-2 mx-2 rounded-circle text-white text-center fw-bold bg-purpleStrong mb-3"
          >5</span
        >
        <div
          type="button"
          :class="[
            'd-inline-block rounded-pill px-3 py-1 ms-3 text-white bg-purpleStrong',
            { 'opacity-50': isMaxFilesReached },
          ]"
          :disabled="isMaxFilesReached"
          :style="{ pointerEvents: isMaxFilesReached ? 'none' : 'auto' }"
          @click="triggerFileInput"
        >
          {{ langFilter("select_files") }}
        </div>
        <input
          ref="fileInput"
          type="file"
          class="d-none"
          :disabled="isMaxFilesReached"
          multiple
          accept=".c, .cs, .cpp, .csv, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .sh, .ts, .xlsx"
          @change="handleFileUpload"
        />
        <p v-if="files.length === 0" class="text-muted fst-italic">
          {{ langFilter("files-limit1") }} {{ maxFiles }} {{ langFilter("files-limit2") }}
        </p>
        <p v-else-if="files.length < maxFiles">
          {{ langFilter("have") }} {{ files.length }} {{ langFilter("file") }}{{
            files.length === 1 ? "" : "s"
          }}
          {{ langFilter("attached") }}{{ files.length === 1 || lang === 'en' ? "" : "s" }}. {{ langFilter("upload") }}
          {{ maxFiles - files.length }} {{ langFilter("file") }}{{
            maxFiles - files.length === 1 ? "" : "s"
          }}
          {{ langFilter("more") }}.
        </p>
        <p v-else>
          {{ langFilter("files-limit-reached1") }}
          {{ maxFiles }} {{ langFilter("files-limit-reached2") }}
        </p>
        <ul class="list-group mt-3 mx-3">
          <li
            v-for="(file, index) in files"
            :key="index"
            :class="[
              'd-flex justify-content-between align-items-center px-5 py-2 my-1',
              {
                'bg-hover-file shadow-sm': hoverFile === index,
              },
            ]"
            @mouseover="hoverFile = index"
            @mouseleave="hoverFile = null"
          >
            <div>
              <img
                src="../../assets/img/track/file-assistant.svg"
                class="pb-1"
              />
              {{ file.name }}
            </div>
            <div class="d-flex">
              <div v-if="canConvertFile(file)" class="file-checkbox-container">
                <label class="file-label-content">
                  <input
                    checked
                    type="checkbox"
                    class="file-checkbox"
                    @change="(e) => handleChange(index, e)"
                  />
                  <span>{{ langFilter("convertToText") }}</span>
                </label>
              </div>

              <img
                width="20"
                class="pointer delete-icon"
                :src="require('@/assets/img/projects/delete.svg')"
                @click="removeFile(index)"
              />
            </div>
          </li>
        </ul>
      </div>

      <AssistantExtraForm
        v-model:temperature="form.temperature"
        v-model:topP="form.topP"
        :lang="lang"
        @validation-change="updateValidation"
      />

      <div
        v-if="!loading"
        type="submit"
        :class="[
          'd-inline-block rounded-pill px-3 py-1 ms-auto text-white bg-purpleStrong',
          {
            'opacity-50': !isFormValid,
          },
        ]"
        :style="{ pointerEvents: isFormValid ? 'auto' : 'none' }"
        @click="isFormValid ? submitForm() : null"
      >
        {{ langFilter("create") }}
      </div>
      <div v-else class="d-flex justify-content-end align-items-center">
        <span class="text-primary me-4">{{
          createdAssistant
            ? `${langFilter("uploadingFiles")}.. ${uploadedFilesCount}/${
                files.length
              }`
            : `${langFilter("creatingAssistant")}... `
        }}</span>
        <span
          class="spinner-border text-primary"
          role="status"
          style="margin-right: 20px"
        />
      </div>
    </form>
  </div>

  <alertMessage ref="alerts" :lang="lang"></alertMessage>
</template>

<script>
import axios from "axios";
import { defineAsyncComponent } from "vue";
import translationMixin from "@/mixins/translationMixin";
import translations from "./translate/AssistantTranslate.json";
import useAssistantForm from "@/mixins/useAssistantForm";
import { useDebugMode } from "@/debug.js";
import { fileType } from "../gpt/model/fileType";

export default {
  name: "NewAssistant",
  components: {
    AssistantExtraForm: defineAsyncComponent(
      () => import("@/components/assistants/AssistantExtraForm.vue")
    )
  },
  mixins: [translationMixin, useAssistantForm],
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    lang: {
      type: String,
      default: "es",
    },
    homeNotifications: {
      type: Array,
      default: () => [],
    },
    role: {
      type: [String, Number],
      default: "",
    },
  },
  emits: ["openAlert"],
  data() {
    return {
      createdAssistant: false,
      loading: false,
      hoverFile: null,
      form: {
        type: "",
        name: "",
        description: "",
        instructions: "",
        files: [],
        temperature: "",
        topP: ""
      },
      maxFiles: 20,
      error: false,
      errors: [],
      succesful: [],
      isDebugMode: false,
      traducciones: translations,
      isExtraFormValid: false,
    };
  },
  computed: {
    files() {
      return Array.from(this.form.files);
    },
    isFormValid() {
      return this.areCommonFieldsValid && this.isExtraFormValid;
    },
    areCommonFieldsValid() {
      const requiredFields = ['type', 'name', 'description', 'instructions'];
      return requiredFields.every(field => Boolean(this.form[field]));
    },
    isMaxFilesReached() {
      return this.files.length >= this.maxFiles;
    }
  },
  created() {
    const { isDebugMode } = useDebugMode();
    this.isDebugMode = isDebugMode;
  },
  methods: {
    getExtension(fileName) {
      return fileName
        .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
        .toLowerCase();
    },
    triggerFileInput() {
      if (!this.isMaxFilesReached) {
        this.$refs.fileInput.click();
      }
    },
    isValidFileExtension(fileName) {
      const allowedExtensions = [
        ".c",
        ".cs",
        ".cpp",
        ".csv",
        ".doc",
        ".docx",
        ".html",
        ".java",
        ".json",
        ".md",
        ".pdf",
        ".php",
        ".pptx",
        ".py",
        ".rb",
        ".tex",
        ".txt",
        ".css",
        ".jpeg",
        ".jpg",
        ".js",
        ".gif",
        ".png",
        ".sh",
        ".ts",
        ".xlsx",
      ];

      const extension = this.getExtension(fileName);
      return allowedExtensions.includes(`.${extension}`);
    },
    handleFileUpload(event) {
      const selectedFiles = Array.from(event.target.files);

      const validFiles = selectedFiles.filter((file) =>
        this.isValidFileExtension(file.name)
      );

      const remainingSlots = this.maxFiles - this.files.length;

      const filesToAdd = validFiles.slice(0, remainingSlots);

      this.form.files = [...this.files, ...filesToAdd];
      this.markFilesForConversion();

      this.$refs.fileInput.value = null;
    },
    markFilesForConversion() {
      this.form.files.forEach((file) => {
        file.convert = fileType.isExtensionToConvert(file.name);
      });
    },
    removeFile(index) {
      const filesArray = Array.from(this.form.files);
      filesArray.splice(index, 1);
      this.form.files = filesArray;
    },
    setAlertText(
      title = "Ups... Something went wrong!",
      text = "Please try again later"
    ) {
      if (!this.$refs.alerts) return;
      this.$refs.alerts.title = title;
      this.$refs.alerts.text = text;
    },
    openErrorsAlert(title, text) {
      this.setAlertText(title, text);
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      this.errors = [];
    },
    handleError(action, error) {
      console.error(`Error ${action}`, error);
    },
    canConvertFile({ name }) {
      return this.isDebugMode && fileType.isExtensionToConvert(name);
    },
    handleChange(index, event) {
      const checked = event.target.checked;
      this.form.files[index].convert = checked;
    },
    updateValidation(isValid) {
      this.isExtraFormValid = isValid;
    },
    async submitForm() {
      this.loading = true;

      try {
        const {
          name,
          description,
          instructions,
          type: assistant_type,
          temperature,
          topP,
        } = this.form;

        const formFields = {
          space_id: this.spaces[0].id,
          name: name,
          description: description,
          instructions: instructions,
          prompt: instructions,
          assistant_type: assistant_type,
          temperature,
          top_p: topP,
        };

        const assistantData = this.createFormData(formFields);

        const data = await this.submitData(assistantData);

        await this.uploadFilesSequentially({
          assistantId: data.id,
          files: this.form.files,
        });

        this.$router.push("/assistants");
      } catch (error) {
        this.openErrorsAlert();
        this.handleError("submitting form", error);
      } finally {
        this.loading = false;
        this.createdAssistant = false;
      }
    },
    async submitData(payload) {
      try {
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_URL}/spaces/${this.spaces[0].id}/assistants`,
          payload
        );

        this.createdAssistant = true;
        return data;
      } catch (error) {
        this.handleError("creating assistant", error);
        throw error;
      }
    },
  },
};
</script>

import { reactive, computed } from 'vue'

// Create the space config state and methods
const createSpaceConfig = () => {
  const state = reactive({
    RESTRICTIONS: [],
    ROLES: {
      EXPERT: 1,
      EVALUATOR: 2
    }
  })

  const RESTRICTIONS_IDS = computed(() => 
    state.RESTRICTIONS.map(restriction => restriction.space_id)
  )

  const getRestrictions = () => {
    const str = process.env.VUE_APP_SPACE_RESTRICTIONS
    if (!str) {
      throw new Error('Space restrictions configuration is not defined')
    }
    return JSON.parse(
      str
        .replace(/\s/g, '')
        .replace(/"(\w+)":/g, '"$1":')
    )
  }

  const hasRestrictions = (id) => {
    return RESTRICTIONS_IDS.value.some(spaceId => spaceId == id)
  }

  const getOptionsBySpaceId = (spaceId) => {
    if (!spaceId) return null
    
    const options = state.RESTRICTIONS.map(restriction => {
      let object = {
        space_id: restriction.space_id,
        limit_date: restriction.limit_date,
        member: {
          tabs: [0]   
        },
        tutor: {
          tabs: [0]
        }
      }

      Object.keys(state.ROLES).forEach(role => {
        if (restriction.member_assistants.includes(role)) {
          object.member.tabs.push(state.ROLES[role])
        }
        if (restriction.tutor_assistants.includes(role)) {
          object.tutor.tabs.push(state.ROLES[role])
        }
      })

      return object
    })

    return options.find(option => option.space_id == spaceId)
  }

  const isAfterLimitDate = (spaceId) => {
    if (!hasRestrictions(spaceId)) return false

    const space = getOptionsBySpaceId(spaceId)
    if (!space?.limit_date) return false

    const limitDate = new Date(space.limit_date)
    if (isNaN(limitDate.getTime())) {
      throw new Error(`Invalid date format for space ${spaceId}`)
    }

    const currentDate = new Date()
    return currentDate > limitDate
  }

  // Initialize restrictions
  state.RESTRICTIONS = getRestrictions()

  return {
    RESTRICTIONS: state.RESTRICTIONS,
    ROLES: state.ROLES,
    RESTRICTIONS_IDS,
    hasRestrictions,
    getOptionsBySpaceId,
    isAfterLimitDate
  }
}

// Create plugin
export default {
  install: (app) => {
    const spaceConfig = createSpaceConfig()
    app.provide('spaceConfig', spaceConfig)
    app.config.globalProperties.$spaceConfig = spaceConfig
  }
} 
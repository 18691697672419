<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="acc-modal itinerary-users-modal">
    <div class="acc-modal-content">
      <h5>{{ langFilter("inviteUsers") }}</h5>
      <form
        :class="error ? 'itinerary-user-error-form' : ''"
        @submit="addUser($event)"
      >
      <div>
        <input
          v-model="searchUser"
          type="text"
          :placeholder="langFilter('searchUser')"
          @input="filterUsers"
        />
        <button class="invite-btn-new" @click="filterUsers">
          {{ langFilter("add") }}
        </button>
      </div>
        <span v-if="error">{{ langFilter("notValidEmail") }}</span>
      </form>
      <div class="itinerary-users-to-select-container">
        <itinerary-users-modal-user
          v-for="(user, i) in usersInvited"
          :key="i"
          :user="user"
          :usersSelected="usersSelected"
          :usersInvited="usersInvited"
          :lang="lang"
          @select-user="selectUser($event)"
        />
        <itinerary-users-modal-user
          v-for="(user, i) in usersAdded"
          :key="i"
          :user="user"
          :is-selected="true"
          :usersSelected="usersSelected"
          :lang="lang"
          @select-user="selectUser($event)"
        />
        <itinerary-users-modal-user
          v-for="(user, i) in usersToAdd"
          :key="i"
          :user="user"
          :usersSelected="usersSelected"
          :lang="lang"
          @select-user="selectUser($event)"
        />
        <itinerary-users-modal-user
          v-if="usersToAdd.length === 0"
          :key="i"
          :user="newUser"
          :usersSelected="usersSelected"
          :lang="lang"
          @select-user="selectUser($event)"
        />
      </div>

      <div class="itinerary-users-modal-btn">
        <button @click="invite">{{ langFilter("invite") }}</button>
      </div>
    </div>
  </div>

  <div class="acc-modal-backdrop" @click="closeModal()" />
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
    projectId: {
      type: [String, Number],
      required: true,
    },
    addingUsers: {
      type: String,
      required: true,
    },
    usersNotInProject: {
      type: Array,
      default: () => []
    }
  },
  emits: [
    "closeModal",
    "addUsers",
    "addProjectUsers",
    "selectUserToInvite",
    "handleUnselect",
    "restoreList",
  ],
  data() {
    return {
      usersSelected: [],
      usersSelectedEmails: [],
      users: [],
      usersToAdd: [],
      usersAdded: [],
      usersInvited: [],
      usersInvitedEmails: [],
      searchUser: "",
      newUser: { id: 0, name: "", email: "" },
      error: false,
      traducciones: [
        {
          name: "inviteUsers",
          es: "INVITAR USUARIO AL PROYECTO",
          en: "INVITE USER TO PROJECT",
        },
        {
          name: "searchUser",
          es: "Buscar usuario existente o añadir email",
          en: "Search existing user or add email",
        },
        {
          name: "invite",
          es: "Invitar",
          en: "Invite",
        },
        {
          name: "add",
          es: "Añadir",
          en: "Add",
        },
        {
          name: "noUsers",
          es: "No hay usuarios",
          en: "There are no users",
        },
        {
          name: "notValidEmail",
          es: "Por favor, introduce un email válido",
          en: "Please, enter a valid email",
        },
      ],
    };
  },
  watch: {
    usersNotInProject(newVal) {
      this.usersToAdd = newVal;
    },
  },
  beforeUnmount() {
    this.$emit("restoreList", this.usersAdded);
  },
  created() {
    this.usersToAdd = this.usersNotInProject;
  },
  methods: {
    getUserRole(type) {
      return type === "tutors" ? 0 : 1;
    },
    addUser(e) {
      e.preventDefault();
      const emailRegex =
        /^[a-zA-Z0-9._-]+([+][a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRegex.test(this.searchUser)) {
        this.error = true;
        return;
      }
      const userExists = this.usersInvited.some(
        (u) => u.email === this.searchUser,
      );
      if (!userExists) {
        this.selectUser({ id: 0, name: "", email: this.searchUser });
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    userSeted(id) {
      return this.usersSelected.includes(id);
    },
    selectUser(user) {
      if (this.isAdded(user)) {
        this.handleUnselect(user);
        this.$emit("handleUnselect", user);
        return;
      }

      const userData = {
        id: user.id,
        role: this.getUserRole(this.addingUsers),
        email: user.email,
        name: user.name,
        avatar:
          "https://api.acceleralia.com/img/wordpresslogos/Avatar-8-150x150.jpg",
      };

      this.usersAdded.push(userData);
      this.usersToAdd = this.usersToAdd.filter(({ id }) => id !== userData.id);
      this.$emit("selectUserToInvite", user);
    },
    isAdded(user) {
      return this.usersAdded.some(({ id }) => id === user.id);
    },
    handleUnselect({ id: userId }) {
      this.usersAdded = this.usersAdded.filter(({ id }) => id !== userId);
    },
    invite() {
      this.$emit("addProjectUsers", {
        type: this.addingUsers,
        data: this.usersAdded,
      });

      this.usersAdded = [];
      this.$emit("closeModal");
    },
    getUsers(users) {
      return users.filter((u) => !this.usersSelected.includes(u.id));
    },
    getUsersSelected(users) {
      return users.filter((u) => this.usersSelected.includes(u.id));
    },
    filterUsers() {
      this.error = false;
      if (!this.searchUser) {
        this.usersToAdd = this.usersNotInProject;
        return;
      }

      const searchLower = this.searchUser.toLowerCase();
      this.usersToAdd = this.usersNotInProject.filter(({ name, email }) =>
        [name, email].some((val) => val.toLowerCase().includes(searchLower)),
      );
    },
  },
};
</script>
<template>
  <div class="col-1 new-template-options">
    <div>
      <hover :content="langFilter('edit')" :bs="true">
        <img
          v-if="edit"
          class="pointer"
          :src="require('@/assets/img/track/edit.svg')"
          alt="edit"
          @click="openModalConfirmation(2)"
        />
      </hover>
    </div>

    <div>
      <hover :content="langFilter('delete')" :bs="true">
        <img
          class="pointer delete-icon"
          :src="require('@/assets/img/projects/delete.svg')"
          alt="delete"
          @click="openModalConfirmation(3)"
        />
      </hover>
    </div>
  </div>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
export default {
  mixins: [translationMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    apartIndex: {
      type: Number,
      default: 0,
    },
    themeIndex: {
      type: Number,
      default: 0,
    },
    questionIndex: {
      type: Number,
      default: 0,
    },
    resourceIndex: {
      type: Number,
      default: 0,
    },
    edit: Boolean,
    lang: {
      type: String,
      default: "en",
    },
  },
  emits: ["openModalConfirmation"],
  data() {
    return {
      traducciones: [
        {
          name: "delete",
          es: "Eliminar",
          en: "Delete",
        },
        {
          name: "edit",
          es: "Editar",
          en: "Edit",
        },
        {
          name: "drag",
          es: "Mover",
          en: "Drag",
        },
      ],
    };
  },
  methods: {
    openModalConfirmation(action) {
      const indexes = [
        this.themeIndex,
        this.apartIndex,
        this.questionIndex,
        this.resourceIndex,
      ];
      this.$emit("openModalConfirmation", [this.type, action, indexes]);
    },
  },
};
</script>

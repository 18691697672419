<template>
  <div
    v-if="
      (!question && !loading && !alreadyAsked) ||
      showProjectChat ||
      showQuestionChat
    "
    v-show="!disabled"
    class="row"
  >
    <chat-bot-project-chat-avatar
      :css-class="[disabled ? 'chat-avatar-disabled' : 'chat-avatar-enabled']"
      :label="getWordInitials(assistantProject?.name)"
    />
    <div
      :class="[
        disabled ? 'bot-message-div-disabled' : 'bot-message-div-enabled',
        'bot-message-div row col-10',
      ]"
    >
      Hola, soy tu asistente de IA entrenado para este proyecto, ¿en qué puedo
      ayudarte?
    </div>
  </div>
  <div v-for="(message, i) in messages" :key="i">
    <div
      v-if="message.message !== 'undefined'"
      :class="[
        disabled
          ? 'chat-user-message-div-disabled'
          : 'chat-user-message-div-enabled',
        'chat-user-message-div row',
      ]"
    >
      <div class="col-12">
        <textarea
          v-if="editing === i"
          class="solution-user-msg-textarea"
          :value="updatedPrompt"
          maxlength="4096"
          @change="changeUpdatedPrompt($event)"
        />
        <span v-else style="white-space: pre-line">
          {{ message.message }}
        </span>
      </div>
    </div>

    <chat-bot-project-chat-attachment
      :lang="lang"
      :files="message.files"
      :can-change="false"
    />

    <div class="row">
      <chat-bot-project-chat-avatar
        :css-class="[disabled ? 'chat-avatar-disabled' : 'chat-avatar-enabled']"
        :label="getWordInitials(message)"
      />
      <div
        :class="[
          disabled ? 'bot-message-div-disabled' : 'bot-message-div-enabled',
          'bot-message-div row col-10',
        ]"
      >
        <div class="col-1 sol-alignd">
          <button @click="copyContent(message.response)">
            <img :src="Copy" :title="langFilter('copy')" alt="copy icon" />
          </button>
        </div>
        <div class="col-11">
          <div :innerHTML="processedResponse(message.response)" />
        </div>
      </div>
    </div>
  </div>
  <div v-if="disabled && status !== 'deleted'" class="line-reload-global">
    <hr class="chat-line-separator" />
      <img
        class="gpt-reload-logo delete-icon"
        :title="langFilter('DeleteChat')"
        :src="require('@/assets/img/projects/delete.svg')"
        alt="ReloadGpt"
        @click="$emit('reloadAction')"
      />
  </div>

  <alertMessage ref="alerts" :lang="lang"></alertMessage>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import DOMPurify from "dompurify";
import Copy from "./images/copy.png";
import FileGptIcon from "./images/file-gpt-icon.png";
import Reload from "../solution/images/reload.png";

export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    question: {
      type: String,
      required: false,
      default: "",
    },
    showProjectChat: {
      type: Boolean,
      required: true,
    },
    showQuestionChat: {
      type: Boolean,
      required: true,
    },
    alreadyAsked: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    assistantProject: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  emits: ["onSendQuestionPrompt", "reloadAction"],
  data() {
    return {
      Reload: Reload,
      file: [],
      editing: -1,
      Copy: Copy,
      FileGptIcon: FileGptIcon,
      errors: [],
      succesful: [],
      traducciones: [
        {
          name: "copy",
          es: "Copiar respuesta",
          en: "Copy answer",
        },
        {
          name: "DeleteChat",
          es: "Eliminar el chat con el asistente no afectará a la información de tu proyecto, pero puedes eliminarlo para limpiar el historial con él",
          en: "Deleting the chat with the assistant will not affect your project information, but you can delete it to clear your history with it",
        },
      ],
    };
  },
  computed: {
    processedResponse() {
      return (response) => {
        if (!response) return "";
        const sanitizedContent = DOMPurify.sanitize(response);
        const regex = /<\/?(h1|h2|h3|h4|h5)>/gi;
        return sanitizedContent.replace(regex, (match) => {
          return match.replace(/h1|h2|h3|h4|h5/, "h6");
        });
      };
    },
  },
  methods: {
    changeUpdatedPrompt(e) {
      this.updatedPrompt = e.target.value;
    },
    getFilePath(file_name, file_path) {
      return `${process.env.VUE_APP_API_STORAGE}/storage/${file_path}`;
    },
    getWordInitials(message) {
      if (!message) {
        return;
      }

      const words =
        typeof message === "string"
          ? message?.split(" ")
          : message?.name.split(" ");

      const initials = words.map((word) => word.charAt(0).toUpperCase());

      return initials.join("").substr(0, 2);
    },
    copyContent(responseContent) {
      if (!navigator.clipboard) {
        console.error("Clipboard API is not available");
        return;
      }
      // Extracting text content from HTML
      const parser = new DOMParser();
      const doc = parser.parseFromString(responseContent, "text/html");
      let text = doc.body.innerHTML || "";

      // Remove extra spaces while preserving necessary spaces and newlines
      text = text
        .split("\n")
        .map((line) => line.trim().replace(/\s+/g, " ")) // Trim lines and replace multiple spaces with a single space
        .filter((line) => line.length > 0) // Remove empty lines
        .join("\n"); // Join lines with a newline character

      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$refs.alerts.title = "";
          this.$refs.alerts.text = "textCopiedSuccessfully";
          this.$refs.alerts.succesful = true;
          this.openErrorsAlert();
        })
        .catch((err) => {
          this.$refs.alerts.title = "";
          this.$refs.alerts.text = "textCopiedWithError";
          this.$refs.alerts.succesful = false;
          this.openErrorsAlert();
          console.error("Failed to copy text: ", err);
        });
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);
      this.$refs.alerts.succesful = false;
      this.errors = [];
    },
  },
};
</script>

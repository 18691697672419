<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="new-template-container container">
    <new-template-title :stepNum="stepNum" :lang="lang" />
    <new-template-body
      :stepNum="stepNum"
      :title="title"
      :template_assistant_id="template_assistant_id"
      :template_assistant_name="template_assistant_name"
      :assistant_id="assistant_id"
      :evaluator_id="assistant_evaluator_id"
      :lang="lang"
      :space="space"
      :draftCategories="categories"
      :image="myImage"
      @setTitle="setTitle($event)"
      @setTemplateAssistant="setTemplateAssistant($event)"
      @setTemplateAssistantName="setTemplateAssistantName($event)"
      @setTemplateImage="setTemplateImage($event)"
      @enableNext="enableNext($event)"
      @setCategories="setCategories($event)"
      @errorFileUpload="errorFileUpload()"
      @setAssistant="setAssistant($event)"
      @setAssistantEvaluator="setAssistantEvaluator($event)"
    />
    <new-template-template
      v-if="stepNum < 1"
      :stepNum="stepNum"
      :lang="lang"
      :title="title"
      :template_assistant_id="template_assistant_id"
      :template_assistant_name="template_assistant_name"
      :assistant_id="assistant_id"
      :assistant_evaluator_id="assistant_evaluator_id"
      :image="myImage"
      :categories="categories"
      @setTitle="setTitle($event)"
      @setTemplateAssistant="setTemplateAssistant($event)"
      @setTemplateAssistantName="setTemplateAssistantName($event)"
      @setAssistant="setAssistant($event)"
      @setAssistantEvaluator="setAssistantEvaluator($event)"
    />
  </div>
</template>

<script>
/* eslint-disable */
import translationMixin from "../../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],

  props: [
    "lang",
    "space",
    "stepNum",
    "title",
    "image",
    "categories",
    "template_assistant_id",
    "template_assistant_name",
    "assistant_id",
    "assistant_evaluator_id",
  ],
  emits: [
    "setTitle",
    "enableNext",
    "setTemplateImage",
    "setTemplateAssistant",
    "setTemplateAssistantName",
    "setAssistant",
    "setAssistantEvaluator",
    "setCategories",
    "errorFileUpload",
  ],
  data() {
    return {
      myImage: this.image,
    };
  },
  watch: {
    image: function (newVal) {
      this.myImage = newVal;
    },
  },
  methods: {
    setTitle(event) {
      this.$emit("setTitle", event);
      this.$emit("enableNext", true);
    },
    setTemplateImage(event) {
      this.myImage = process.env.VUE_APP_API_STORAGE + event;
      this.$emit("enableNext", true);
      this.$emit("setTemplateImage", event);
    },
    setTemplateAssistant(event) {
      this.$emit("setTemplateAssistant", event);
    },
    setTemplateAssistantName(event) {
      this.$emit("setTemplateAssistantName", event);
    },
    setAssistant(event) {
      this.$emit("setAssistant", event != 0 ? event : null);
    },
    setAssistantEvaluator(event) {
      this.$emit("setAssistantEvaluator", event != 0 ? event : null);
    },
    enableNext(event) {
      this.$emit("enableNext", event);
    },
    setCategories(event) {
      this.$emit("setCategories", event);
    },
    errorFileUpload() {
      this.$emit("errorFileUpload");
    },
  },
};
</script>

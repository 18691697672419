<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="itineray-header">
    <div class="row">
      <div class="col-2 itinerary-btn-title">
        <button
          class="itineray-header-button"
          @click="titlesOpened = !titlesOpened"
        >
          <img
            :src="
              require(
                `@/assets/img/newitinerary/${
                  titlesOpened ? 'arrow-down' : 'arrow'
                }.svg`
              )
            "
            alt="arrow"
          />
        </button>
      </div>
      <div class="col-8">
        <input
          v-if="editing"
          class="itinerary-title-input"
          type="text"
          :value="project.name"
          @input="changeProjectName($event.target.value)"
        />
        <p v-else>
          {{ project.name }}
        </p>
      </div>
      <div
        v-if="view === 0 && space.id !== 22680"
        class="col-2 itinerary-edition-container"
      >
        <button v-if="editing" class="itinerary-save-btn" @click="save()">
          <img
            :src="require(`@/assets/img/newitinerary/save.svg`)"
            alt="save"
          />
        </button>
        <div
          v-if="project.imported_file === 0 && memberCanEdit"
          class="toggle-itinerary"
        >
          <div
            :class="`editon-button ${
              editing ? 'itinerary-button-editing' : 'flex-start'
            }`"
            @click="setEditing()"
          >
            <div />
          </div>
          <span>{{ langFilter("editionMode") }}</span>
        </div>
      </div>
      <div class="col-3" />
      <div class="col-5 new-itinerary-loading-header-container">
        <div class="new-itinerary-loading-header">
          <div :style="{ width: percentage + '%' }" />
        </div>
        <span v-if="percentage >= 100">100</span>
        <span v-else>{{ percentage }}</span>
        %
      </div>
    </div>
    <search-project
      v-if="titlesOpened"
      :lang="lang"
      :projects="allProjects"
      @selectProject="selectProject($event)"
    />
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
import generalMethodsMixin from "@/mixins/generalMethodsMixin.js";
import { ROLES_IN_PROJECT } from "@/constants";
const { MEMBER } = ROLES_IN_PROJECT;

export default {
  mixins: [translationMixin, generalMethodsMixin],
  inject: ['spaceConfig'],
  props: {
    lang: {
      type: String,
      required: true,
    },
    space: {
      type: Object,
      default: () => ({}),
    },
    editing: Boolean,
    project: {
      type: Object,
      default: () => ({}),
    },
    view: {
      type: Number,
      default: 0,
    },
    percentage: {
      type: Number,
      default: 0,
    },
    roleInProject: {
      type: Number,
      default: 0,
    }
  },
  emits: [
    "showConfirmationModalInfo",
    "changeProjectName",
    "setEditing",
    "saveItinerary",
    "selectProject",
  ],
  data() {
    return {
      titlesOpened: false,
      allProjects: [],
      traducciones: [
        {
          name: "title_template_screen",
          es: "Plantillas",
          en: "Templates",
        },
        {
          name: "editionMode",
          es: "Modo edición",
          en: "Edition mode",
        },
        {
          name: "upload",
          es: "Importar",
          en: "Import",
        },
      ],
    };
  },
  computed: {
    memberCanEdit() {
      return this.roleInProject !== MEMBER || !this.spaceConfig.isAfterLimitDate(this.space.id);
    },
  },
  created() {
    axios
      .get(
        `${process.env.VUE_APP_API_URL}/getAllProjectsBySpace/${this.space.id}`
      )
      .then((response) => {
        this.allProjects = response.data;
      });
  },
  methods: {
    uploadGpt(event) {
      const file = event.target.files[0];

      // Get the value of the clean_project checkbox
      const cleanProjectCheckbox = document.querySelector(
        "#overwrite-structure"
      );
      const cleanProject = cleanProjectCheckbox.checked ? "on" : "off";

      // Create a FormData instance to hold the file and other data
      const formData = new FormData();
      formData.append("doc", file);
      formData.append("clean_project", cleanProject);
      formData.append("project_id", this.project.id);
      formData.append("space_id", this.space.id);

      // Make the axios call
      axios
        .post(`${process.env.VUE_APP_API_URL}/newProject/fromFile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$emit(
            "showConfirmationModalInfo",
            "Archivo subido correctamente"
          );
          if (cleanProject === "on") {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          console.error(error);
          this.$emit(
            "showConfirmationModalInfo",
            "Ha habido un error al subir el archivo. Por favor, inténtalo de nuevo."
          );
        });
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    changeProjectName(name) {
      this.$emit("changeProjectName", name);
    },
    setEditing() {
      this.$emit("setEditing");
    },
    save() {
      this.$emit("saveItinerary");
    },
    selectProject(id) {
      this.titlesOpened = false;
      this.$emit("selectProject", id);
    },
  },
};
</script>

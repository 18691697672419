export const ROLES_IN_PROJECT = {
  MEMBER: 0,
  TUTOR: 1,
};

export const ROLES_IN_SPACE = {
  ADMIN: 1,
  MEMBER: 2,
};

export const TABS_TYPE = {
  ITINERARY: 0,
  EXPERT: 1,
  EVALUATOR: 2,
};

export const ASSISTANTS_TYPE = {
  EXPERT: 1,
  EVALUATOR: 2,
};

export const ITINERARY_VIEWS = {
  ITINERARY: 0,
  USERS: 1,
  RESOURCES: 2,
  SMART_PRINTING: 3,
  TASK_MANAGER: 4,
  SAVE: 5,
};

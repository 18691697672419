<!-- eslint-disable vue/prop-name-casing -->
<template>
  <div>
    <div
      v-if="!cdti"
      id="solution-map-component"
      class="solution-map-div"
      :class="{ mapOpened: mapOpened }"
    >
      <img
        class="solution-close-map"
        :src="mapImageSrc"
        alt="close-map"
        @click="$emit('handleMapOpened', !mapOpened)"
      />
      <progressbar-solution
        :class="[
          mapOpened
            ? 'percentage-solution-map'
            : 'percentage-solution-map-closed',
        ]"
        :mapOpened="mapOpened"
        :map="true"
        :percentage="percentage"
      />

      <br />

      <div
        :class="[
          !mapOpened ? 'solutions-map-div-closed' : 'd-flex flex-wrap pe-2',
        ]"
      >
        <div
          v-if="projectHasTutors"
          class="solution-btn-map"
          :class="openCloseClass"
          @click="sendCorrection()"
          @mouseover="showHideHover('correct', true)"
          @mouseleave="showHideHover('correct', false)"
        >
          <img
            :src="require('@/assets/img/solution/send_correct.svg')"
            alt="Send Correct"
          />
          <span v-show="mapOpened" class="send-correct-txt">
            <span>
              {{ langFilter(isTutor ? "sendCorrect" : "sendCorrect2") }}
            </span>
          </span>

          <span
            v-if="hoverStates.correct && !mapOpened"
            class="general-hover hover-side"
          >
            {{ langFilter(isTutor ? "sendCorrect" : "sendCorrect2") }}
          </span>
        </div>

        <div
          class="solution-btn-map"
          :class="[
            openCloseClass,
            mapOpened
              ? 'solution-btn-map-index-opened'
              : 'solution-btn-map-index',
          ]"
          @click="$emit('handleMapOpened', !mapOpened)"
          @mouseover="showHideHover('index', true)"
          @mouseleave="showHideHover('index', false)"
        >
          <img
            :src="require('@/assets/img/solution/index_logo.svg')"
            alt="Index"
          />
          <span v-show="mapOpened" class="send-correct-txt">{{
            langFilter("index")
          }}</span>

          <span
            v-if="hoverStates.index && !mapOpened"
            class="general-hover hover-side"
          >
            {{ langFilter("index") }}
          </span>
        </div>

        <div
          class="solution-btn-map solution-btn-map-resource"
          :class="[openCloseClass, {'solution-btn-disabled': !projectHasResources}]"
          @click="openModalResources()"
          @mouseover="showHideHover('resource', true)"
          @mouseleave="showHideHover('resource', false)"
        >
          <img
            :src="require('@/assets/img/solution/resources_icon.svg')"
            alt="Resources"
          />

          <span
            v-if="hoverStates.resource"
            class="general-hover"
            :class="[mapOpened ? 'hover-top' : 'hover-side']"
          >
            {{ langFilter("resources") }}
          </span>
        </div>

        <div
          class="solution-btn-map"
          :class="[openCloseClass, { 'solution-btn-disabled': !taskManager }]"
          @mouseover="showHideHover('taskmanager', true)"
          @mouseleave="showHideHover('taskmanager', false)"
        >
          <a :href="taskManager" target="_blank" class="task-manager-link">
            <img
              class="map-btn-img"
              :src="require(`@/assets/img/right-sidebar/taskManager.webp`)"
              alt="Task Manager"
            />
            <span v-show="mapOpened">{{ langFilter("Task Manager") }}</span>
          </a>

          <span
            v-if="hoverStates.taskmanager && !mapOpened"
            class="general-hover hover-side"
          >
            {{ langFilter("Task Manager") }}
          </span>
        </div>
        
        <div
          class="solution-btn-map solution-btn-open-resources"
          :class="openCloseClass"
          @click="$emit('showOptionsPopup')"
          @mouseover="showHideHover('print', true)"
          @mouseleave="showHideHover('print', false)"
        >
          <img
            :src="require(`@/assets/img/right-sidebar/smartPrinting.webp`)"
            alt="Smart Printing"
          />
          <span
            v-if="hoverStates.print"
            class="general-hover"
            :class="[mapOpened ? 'hover-bottom' : 'hover-side']"
          >
            Smart Printing
          </span>
        </div>

        <div
          v-if="isDebugMode"
          class="new-component-test solution-btn-map solution-btn-open-resources"
          :class="openCloseClass"
          @click="downloadProjectStructure()"
          @mouseover="showHideHover('download', true)"
          @mouseleave="showHideHover('download', false)"
        >
          <img
            src="/img/recursos/download_white.svg"
            alt="Download"
          />

          <span
            v-if="hoverStates.download"
            class="general-hover"
            :class="[mapOpened ? 'hover-bottom' : 'hover-side']"
          >
            {{ langFilter("download") }}
          </span>
        </div>
      </div>
      <!-- map content -->
      <div
        v-if="reloadedMap"
        v-show="mapOpened"
        :key="changedProject"
        class="solution-map-content"
      >
        <!-- Start tag -->
        <div class="d-flex align-items-start mt-2">
          <div class="flex-column justify-content-center align-items-center">
            <svg height="20" width="25" class="d-block">
              <circle fill="#13C99E" cx="12" cy="10" r="6" />
            </svg>
            <svg width="2" height="14" stroke="#B4BAC5" class="d-block mx-auto">
              <line stroke-width="2" y1="14"></line>
            </svg>
          </div>
          <div class="solution-start-finish-map solution-start ms-2">
            {{ langFilter("start") }}
          </div>
        </div>

        <!-- Content -->
        <div
          v-for="(station, stationIndex) in lateralBarMap"
          :key="stationIndex"
        >
          <div class="d-flex align-items-center">
            <div class="flex-column justify-content-center align-items-center">
              <svg
                width="2"
                height="10"
                stroke="#B4BAC5"
                class="d-block mx-auto"
              >
                <line stroke-width="2" y1="10"></line>
              </svg>
              <svg class="d-block" height="20" width="25">
                <circle
                  cx="12"
                  cy="10"
                  r="6"
                  :fill="
                    station.anyInteraction
                      ? '#dc731c'
                      : station.allPartsAnswered
                        ? '#13C99E'
                        : '#B4BAC5'
                  "
                />
              </svg>
              <svg
                width="2"
                height="10"
                stroke="#B4BAC5"
                class="d-block mx-auto"
              >
                <line stroke-width="2" y1="10"></line>
              </svg>
            </div>
            <div class="solution-map-station ms-2">
              {{ stationIndex + 1 }}. {{ station.nameStation }}
            </div>
          </div>

          <div
            v-for="(solution, solutionIndex) in station.parts"
            :key="solutionIndex"
            :class="{
              'd-flex align-items-center ': true,
              'solution-map-selected rounded-end':
                solution.idPart === solutionId,
            }"
          >
            <div class="flex-column justify-content-center align-items-center">
              <svg
                width="2"
                height="5"
                stroke="#B4BAC5"
                class="d-block mx-auto"
              >
                <line stroke-width="2" y1="5"></line>
              </svg>
              <svg class="d-block" height="10" width="25">
                <circle
                  cx="12"
                  cy="5"
                  r="3"
                  :fill="
                    solution.anyInteraction
                      ? '#dc731c'
                      : solution.allQuestionsAnswered
                        ? '#13C99E'
                        : '#B4BAC5'
                  "
                />
              </svg>
              <svg
                width="2"
                height="5"
                stroke="#B4BAC5"
                class="d-block mx-auto"
              >
                <line stroke-width="2" y1="5"></line>
              </svg>
            </div>
            <div
              class="solution-map-solution ms-2"
              @click="
                goToSolution(
                  station.idStation,
                  solution.idPart,
                  solution.namePart
                )
              "
            >
              {{ solution.namePart }}
            </div>
          </div>
        </div>

        <!-- Finish tag -->
        <div class="d-flex align-items-end">
          <div class="flex-column justify-content-center align-items-center">
            <svg
              width="2"
              height="14"
              :stroke="percentage == 100 ? '#13C99E' : '#B4BAC5'"
              class="d-block mx-auto"
            >
              <line stroke-width="2" y1="16"></line>
            </svg>
            <svg class="solution-grand-circle" height="20" width="25">
              <circle
                cx="12"
                cy="10"
                r="6"
                :fill="percentage == 100 ? '#13C99E' : '#B4BAC5'"
              />
            </svg>
          </div>
          <div class="solution-start-finish-map solution-finish ms-2">
            {{ langFilter("finished") }}
          </div>
        </div>
      </div>
      <img
        v-else
        class="solutions-loading-gif"
        :src="require('@/assets/img/general/loading.gif')"
      />
    </div>
    <!-- CDTI -->
    <div
      v-else
      id="solution-map-component"
      :class="[
        {
          mapOpened: mapOpened,
        },
        'solution-map-div',
      ]"
    >
      <div class="solution-btn-map-cdti" @click="sendInvertibility()">
        <img
          :src="require('@/assets/img/solution/send_correct.svg')"
          alt="Send Correct"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { useDebugMode } from "@/debug.js";
import closeMap from "@/assets/img/solution/close-map.svg";
import openMap from "@/assets/img/solution/open-map.svg";
import { ROLES_IN_PROJECT } from "@/constants";
const { TUTOR } = ROLES_IN_PROJECT

export default {
  props: [
    "projectId",
    "solutionId",
    "allStations",
    "allSolutions",
    "role",
    "lang",
    "percentage",
    "changedProject",
    "solutionUpdated",
    "cdti",
    "lateralBarMap",
    "mapOpened",
  ],
  emits: [
    "showOptionsPopup",
    "openModalResources",
    "loadingCdti",
    "getCdtiData",
    "closeCdtiModal",
    "goToSolution",
    "sendCorrection",
    "handleMapOpened",
  ],
  inject: ["projectHasTutors", "showAlert"],
  data() {
    return {
      itineraryData: [],
      status: [],
      stationsColors: [],
      allCompleted: false,
      notificationMap: [],
      reloadedMap: true,
      hoverStates: {
        resource: false,
        index: false,
        correct: false,
        taskmanager: false,
        print: false,
        download: false,
      },
      allAnswers: [],
      color: "#B4BAC5",
      solutionsStatus: [],
      allSolutionsMap: [],
      cont: 0,
      cdtiData: [],
      taskManager: "",
      projectResources: [],
      url: process.env.VUE_APP_API_STORAGE,
      isDebugMode: false,
      localSolutionUpdated: false,
      traducciones: [
        {
          name: "start",
          es: "Inicio",
          en: "Start",
        },
        {
          name: "finished",
          es: "Finalizado",
          en: "Finished",
        },
        {
          name: "sendCorrect2",
          es: "Solicitar revisión",
          en: "Request review",
        },
        {
          name: "sendCorrect",
          es: "Enviar revisión",
          en: "Send review",
        },
        {
          name: "index",
          es: "Índice",
          en: "Index",
        },
        {
          name: "resources",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "Task Manager",
          es: "Gestor de tareas",
          en: "Task Manager",
        },
        {
          name: "taskManager",
          es: "Gestor de tareas",
          en: "Task Manager",
        },
        {
          name: "allQuestions",
          es: "Por favor responde al menos 8 preguntas.",
          en: "Please answer at least 8 questions.",
        },
        {
          name: "download",
          es: "Descargar",
          en: "Download",
        },
      ],
    };
  },
  watch: {
    changedProject: function () {
      this.reloadedMap = false;
    },
    solutionUpdated: function (newVal) {
      this.localSolutionUpdated = newVal;
    },
  },
  computed: {
    isTutor() {
      return this.role === TUTOR;
    },
    openCloseClass() {
      const status = this.mapOpened ? "opened" : "closed";
      return `solution-btn-map-${status}`;
    },
    mapImageSrc() {
      return this.mapOpened ? closeMap : openMap;
    },
    projectHasResources() {
      return this.projectResources[0]?.length > 0;
    }
  },
  async created() {
    const { isDebugMode } = useDebugMode();
    this.isDebugMode = isDebugMode;
    const promises = [
      this.getResources(),
      this.getTaskManager(),
    ];
    if (this.cdti) {
      this.$emit("handleMapOpened", false);
    } else {
      this.$emit("handleMapOpened", true);
      this.localSolutionUpdated = this.solutionUpdated;
      promises.push(this.getNotificationMap());
      promises.push(this.getAnswersByProject());
      this.checkIfSolutionCompleted();
    }

    try {
      await Promise.all(promises);
    } catch (error) {
      this.projectResources = [];
      this.showAlert({
        title: "error",
        text: "Ups... Something went wrong!",
      });
      console.error("Ups... Something went wrong!", error);
    }
  },
  methods: {
    openModalResources() {
      this.$emit("openModalResources");
    },
    sendInvertibility() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/cdti/getInsertedInvertibility/${this.projectId}/${this.solutionId}`
        )
        .then((response) => {
          const data = response.data;
          this.cdtiData = data;
          if (data.length < 8) {
            alert(this.langFilter("allQuestions"));
          } else {
            let result = data.find((item) => item.question_id === 32309);
            const data0 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32310);
            const data1 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32311);
            const data2 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32313);
            const data3 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32316);
            const data4 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32319);
            const data5 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32322);
            const data6 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32324);
            const data7 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32326);
            const data8 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32329);
            const data9 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32334);
            const data10 = result ? result.answer : null;

            axios
              .post(
                "https://acctest123.acceleralia.com:8443/answer/run-inference",
                {
                  q1: data0,
                  q2: data1,
                  q3: data2,
                  q4: data3,
                  q5: data4,
                  q6: data5,
                  q7: data6,
                  q8: data7,
                  q9: data8,
                  q10: data9,
                  q0: data10,
                }
              )
              .then((response) => {
                this.$emit("loadingCdti", true);
                const data = response.data;

                let step2 = [];
                data.explanation.forEach(function (element) {
                  if (
                    element.invertibility_index > data.invertibility_index &&
                    element.question_id !== "Q1" &&
                    element.question_id !== "Q0"
                  )
                    step2.push(element);
                });

                const result = step2.reduce((acc, curr) => {
                  if (
                    !acc[curr.question_id] ||
                    acc[curr.question_id].invertibility_index <
                      curr.invertibility_index
                  ) {
                    acc[curr.question_id] = curr;
                  }
                  return acc;
                }, {});

                //discard all the results with the object new_value = null or 0
                Object.keys(result).forEach(function (key) {
                  if (
                    result[key].new_value === null ||
                    result[key].new_value === 0
                  ) {
                    delete result[key];
                  }
                });

                const shuffled = Object.values(result).sort(
                  () => Math.random() - 0.5
                );
                const random3 = shuffled
                  .slice(0, 3)
                  .sort((a, b) => a.question_id.localeCompare(b.question_id));
                console.log("result", random3);
                this.$emit("getCdtiData", [data, random3, this.cdtiData]);
                this.$emit("closeCdtiModal", true);
                this.$emit("loadingCdti", false);
              });
          }
        });
    },
    getAnswersByProject() {
      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/solution/getAnswersByProject/${this.projectId}`
        )
        .then((response) => {
          this.allAnswers = response.data;
        });
    },
    solutionCompleted(solutionId, completed) {
      completed
        ? (this.solutionsStatus[solutionId] = "#13C99E")
        : (this.solutionsStatus[solutionId] = "#B4BAC5");
      this.checkIfStationCompleted();
    },
    getNotificationMap() {
      return axios
        .post(`${process.env.VUE_APP_API_URL}/solution/getNotificationMap`, {
          project_id: this.projectId,
          role: this.role,
        })
        .then((response) => {
          this.notificationMap = response.data;
        });
    },
    getResources() {
      return axios
        .get(`${process.env.VUE_APP_API_URL}/getResourcesByProject/${this.projectId}`)
        .then((response) => {
          this.projectResources = response.data;
        });
    },
    getTaskManager() {
      return axios
        .get(`${process.env.VUE_APP_API_URL}/myProjects/getProjectTaskManager/${this.projectId}`)
        .then((response) => {
          const { task_manager_url } = response.data;
          this.taskManager = task_manager_url;
        });
    },
    goToSolution(stationId, solutionId, solution_name) {
      this.$router.push(`/parts/${this.projectId}/${stationId}/${solutionId}`);
      this.$emit("goToSolution", [stationId, solutionId, solution_name]);
    },
    showHideHover(type, show) {
      if (type === "taskManager" && !this.taskManager) {
        return;
      }

      this.hoverStates[type] = show;
    },
    sendCorrection() {
      this.$emit("sendCorrection");
    },
    checkIfSolutionCompleted() {
      //wait 0.5 seconds to check if all solutions are completed
      setTimeout(() => {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}/solution/getSolutionsByProject/${this.projectId}`
          )
          .then((response2) => {
            this.allSolutionsMap = response2.data;
            let allSol = [];
            this.allSolutionsMap.forEach((solution) => {
              // if solution.id is in allSol
              if (!allSol.includes(solution.id)) {
                allSol.push(solution.id);
              }
            });
            for (let solution in allSol) {
              const solutionId = allSol[solution];
              //filter all answers by solutionId

              let answers = this.allAnswers.filter(
                (answer) => answer.solutionId === solutionId
              );
              //delete all answers with null answer value
              answers = answers.filter(
                (answer) => answer.answer !== null && answer.answer !== ""
              );
              //count all answers by solutionId
              const totalAnswers = answers.length;
              axios
                .get(
                  `${process.env.VUE_APP_API_URL}/solution/getQuestionsBySolution/${solutionId}`
                )
                .then((response) => {
                  if (
                    (totalAnswers === response.data ||
                      totalAnswers > response.data) &&
                    response.data !== 0
                  ) {
                    this.solutionsStatus[solutionId] = "#13C99E";
                  } else {
                    this.solutionsStatus[solutionId] = "#B4BAC5";
                  }
                  this.cont++;
                });
            }

            //execute every 250ms until this.allSolutionsMap.length === cont
            const interval = setInterval(() => {
              if (this.allSolutionsMap.length === this.cont) {
                clearInterval(interval);
                this.cont = 0;
                this.checkIfStationCompleted();
              }
            }, 250);
          });
      }, 1000);
    },
    checkIfStationCompleted() {
      //group all solutions by stationId
      let groupedSolutions = this.allSolutionsMap.reduce(
        (r, a) => {
          r[a.stationId] = [...(r[a.stationId] || []), a];
          return r;
        },
        { 0: [] }
      );
      //delete all empty solutions
      delete groupedSolutions[0];
      //iterate over all stations
      for (let station in groupedSolutions) {
        const stationId = groupedSolutions[station][0].stationId;
        //iterate over all solutions by stationId
        for (let solution in groupedSolutions[station]) {
          const solutionId = groupedSolutions[station][solution].id;
          //check if solution is completed
          if (this.solutionsStatus[solutionId] === "#B4BAC5") {
            this.stationsColors[stationId] = "#B4BAC5";
            break;
          } else {
            this.stationsColors[stationId] = "#13C99E";
          }
        }
      }
    },
    async downloadProjectStructure() {
      try {
        const response = await axios
          .get(
            `${process.env.VUE_APP_API_URL}/projects/${this.projectId}/structure`,
            {
              responseType: "blob", // Ensure the response is treated as a binary file (Blob)
            }
          )
          .finally(() => {
            // Any finalization logic can go here
          })
          .catch((error) => {
            console.error("Error getting project structure", error);
          });

        if (response && response.data) {
          // Create a new Blob object for the response data
          const blob = new Blob([response.data], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);

          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = `${this.project_name || "project_structure"}.txt`;
          link.click();

          // Clean up the object URL
          window.URL.revokeObjectURL(url);
        } else {
          console.error("No data found in response", response);
        }

        console.log(response);
      } catch (error) {
        console.error("Error getting project structure", error);
      }
    },
    langFilter(key) {
      const translation = this.traducciones.find((t) => t.name === key);
      return translation ? translation[this.lang] : key;
    },
  },
};
</script>
